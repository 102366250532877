import {ADD_ASCASTOUT,  GET_ASCASTOUT } from "./constants";

const ascastOutReducer = (state = [], action) => {
    switch(action.type) {
        case GET_ASCASTOUT:
                return [...action.payload];
        case ADD_ASCASTOUT:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default ascastOutReducer;