import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { addAscastout } from "../../redux/ascastOutAction";
import { addFinishout } from "../../redux/outfinishAction";
import { useLocation } from "react-router-dom";

const AsCastInfoOut = (props) => {
    const dispatch = useDispatch();
    const today = new Date().toISOString().split('T')[0];

    const location = useLocation();
    const { standard_id } = location.state || {};

    const [isOpen, setIsOpen] = useState(false);
    const [finishOutInfo, setFinishOutInfo] = useState({
        customer_out: '',
        shaft_out: '',
        quantity_out: '',
        finish_id: '',
        delete_flg: '0',
        finish_out_date: today
    });

    useEffect(() => {
        if (props.finishOutData) {
            setFinishOutInfo({
                ...props.finishOutData,
                delete_flg: '0'
            });
        }
    }, [props.finishOutData]);

    useEffect(() => {
        console.log("finishOutDataQuantity:", props.finishOutDataQuantity);
    }, [props.finishOutDataQuantity]);

    const getEntriesForShaft = (selectedShaft, selectedFinishId) => {
        console.log("Selected shaft:", selectedShaft);
        console.log("Selected finish_id:", selectedFinishId);
        if (!props.finishOutDataQuantity || props.finishOutDataQuantity.length === 0) {
            console.log("No data available in finishOutDataQuantity");
            return [];
        }

        const filteredData = props.finishOutDataQuantity.filter(item => 
            item.shaft === selectedShaft && item.finish_id === parseInt(selectedFinishId)
        );
        console.log("Filtered data for shaft and ascast_id:", filteredData);

        return filteredData;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'shaft_out') {
            const selectedOption = e.target.selectedOptions[0];
            const finish_id = selectedOption.getAttribute('data-finish-id');
            console.log("Selected option data-ascast-id:", finish_id);
            setFinishOutInfo(prevInfo => ({
                ...prevInfo,
                shaft_out: value,
                quantity_out: '',
                finish_id: finish_id
            }));
        } else {
            setFinishOutInfo(prevInfo => ({
                ...prevInfo,
                [name]: value
            }));
        }
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestData = {
                ...finishOutInfo,
                standard_id: standard_id
            };

            console.log("Submitting data:", requestData);

            const response = await axios.post('http://178.128.110.90/api/finish_out', requestData);
            dispatch(addFinishout(response.data.finish));
            console.log("Response data:", response.data);
            props.handleCancel();
        } catch (err) {
            console.error("Error submitting form: ", err);
        }
    };
    const renderQuantityOptions = () => {
        const entries = getEntriesForShaft(finishOutInfo.shaft_out, finishOutInfo.finish_id);
        if (entries.length === 0) {
            return <option value="" disabled>No quantities available</option>;
        }

        const entry = entries[0];
        const range = Array.from({ length: entry.remaining_quantity }, (_, index) => index + 1);
        return range.map(quantity => (
            <option key={`${entry.finish_id}-${quantity}`} value={quantity}>{quantity}</option>
        ));
    };


    const isFormValid = () => {
        const { customer_out, shaft_out, quantity_out, finish_out_date } = finishOutInfo;
        return customer_out && shaft_out && quantity_out && finish_out_date;
    };

    return (
        <div className="stock-info">
            <h3 className="stock-info__title">FINISH OUT INFO</h3>
            <hr className="stock-info__hr"/>
            <form className="stock-info__form" onSubmit={handleSubmit}>
                <div className="input-field">
                    <label htmlFor="finish_out_date">Date:</label>
                    <input 
                        type="date" 
                        id="finish_out_date" 
                        name="finish_out_date" 
                        value={finishOutInfo.finish_out_date} 
                        min={today} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="customer_out">Customer:</label>
                    <div className="input-container">
                        <input 
                            type="text" 
                            id="customer_out" 
                            name="customer_out" 
                            value={finishOutInfo.customer_out} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor="shaft_out">Shaft:</label>
                    <div className="input-container">
                        <select 
                            id="shaft_out" 
                            name="shaft_out" 
                            value={finishOutInfo.shaft_out} 
                            onChange={handleChange} 
                            required
                            className="quantity-select"
                        >
                             <option value="" disabled>Select shaft</option>
                            {props.finishOutDataQuantity && props.finishOutDataQuantity.map(item => (
                                <option key={`${item.shaft}-${item.finish_id}-${item.id}`} value={item.shaft} data-finish-id={item.finish_id}>
                                    {item.shaft}
                                </option>
                            ))}
                        </select>
                        <span className="custom-arrow"></span> {/* Custom arrow for dropdown */}
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor="quantity">Quantity:</label>
                    <div className="input-container">
                        <div
                            className="dropdown"
                            onClick={toggleDropdown}
                            tabIndex="0" // Makes it focusable
                        >
                            <input
                                type="text"
                                value={finishOutInfo.quantity_out || "Select quantity"}
                                readOnly
                                className="dropdown-input"
                            />
                            <span className="custom-arrow"></span>
                        </div>

                        {isOpen && (
                            <ul className="dropdowns-menu">
                                <li 
                                    onClick={(e) => {
                                        setFinishOutInfo({
                                            ...finishOutInfo,
                                            quantity_out: "" // Reset quantity on 'Select quantity' click
                                        });
                                        setIsOpen(false);
                                    }} 
                                    className="option"
                                >
                                    Select quantity
                                </li>
                                {[...Array(10).keys()].map(i => (
                                    <li
                                        key={i + 1}
                                        onClick={(e) => {
                                            setFinishOutInfo({
                                                ...finishOutInfo,
                                                quantity_out: i + 1
                                            });
                                            setIsOpen(false); // Close dropdown after selection
                                        }}
                                        className="option"
                                    >
                                        {i + 1}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div className="stock-info__form__btns">
                    <button type="button" onClick={props.handleCancel} className="btn btn--text">Cancel</button>
                    <button type="submit" className="btn" disabled={!isFormValid()}>Save</button>
                </div>
            </form>
        </div>
    );
};

export default AsCastInfoOut;
