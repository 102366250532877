import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ADD_BUSHING } from "../redux/constants";
import { setBushing } from "../redux/bushingActions";
import { setBushings } from "../redux/bushingsAction";
import { setBushingsout } from "../redux/bushingsOutAction";
import SearchIcon from '@mui/icons-material/Search';
import AddBushingModal from "../components/AddBushingModal";
import ConfirmationModal from "../components/ConfirmationModal";
import Typography from '@mui/material/Typography';
import SuccessModal from "../components/SuccessModal";
import { useNavigate } from "react-router-dom";
import { getAllBushing, fetchAllBushingsData, fetchAllBushingsOutData } from "../services/apiService";
import { formatDateToMonthDayYear, parseDateFromMonthDayYear } from "../components/DateFormatter" 

const BushingStockList = () => {
    const bushingsData = useSelector(store => store.bushings);
    const bushingsOutData = useSelector(store => store.bushingsout);
    const [showModal, setShowModal] = useState(false);
    const bushings = useSelector(store => store.bushing);
    const [successMessage, setSuccessMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [rows, setRows] = useState([])
    const [editRowId, setEditRowId] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [deleteRowId, setDeleteRowId] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
   
    const bushingOutMap = bushingsOutData.reduce((map, item) => {
        if (!map[item.bushings_id]) {
            map[item.bushings_id] = 0;
        }
        map[item.bushings_id] += item.quantity_out;
        return map;
    }, {});
    
    // Subtract the quantity from finishData based on finish_id
    const updatedBushingData = bushingsData.map(item => {
        const quantityOut = bushingOutMap[item.bushings_id] || 0;
        return {
            ...item,
            quantity: item.quantity - quantityOut
        };
    });
    
    const fetchData = async () => {
        try {
            const bushing = await getAllBushing();
            dispatch(setBushing(bushing));
            const bushings = await fetchAllBushingsData();
            dispatch(setBushings(bushings));
            const bushingsOut = await fetchAllBushingsOutData();
            dispatch(setBushingsout(bushingsOut));
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };
    const handleEditClick = (row) => {
        setEditRowId(row.id);
        // Set editValues including the date field with the current row's date value
        setEditValues({
            ...row,
            date: row.date ? parseDateFromMonthDayYear(row.date) : null // Assuming date is in the correct format
        });
    };

    const handleSaveClick = async () => {
        try {
            await axios.put(`http://178.128.110.90/api/bushing/${editRowId}`, editValues);
            setRows(rows.map((row) => (row.id === editRowId ? editValues : row)));
            setEditRowId(null);
            setEditValues({});

            fetchData();
            setSuccessMessage('Stock successfully updated.');
            setSuccessModalOpen(true);
        } catch (error) {
            console.error("Failed to save the changes:", error);
        }
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditValues({});
    };

    const handleDelete = async (rowId) => {
        setDeleteRowId(rowId);
        setConfirmModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            await axios.put(`http://178.128.110.90/api/bushing/delete/${deleteRowId}`);
            const updatedBushing = bushings.filter((bushing) => bushing.id !== deleteRowId);
            dispatch(setBushing(updatedBushing));
            setRows(updatedBushing);
            setDeleteRowId(null);
            setConfirmModalOpen(false);
        } catch (error) {
            console.error("Failed to delete the row:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditValues({ ...editValues, [name]: value });
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setEditValues({ ...editValues, [name]: value });
    };
    
    
    function handleOnFilterChange(e) {
        setFilter(e.target.value);
    }
    function handleOnRowClick(event, rowData) {
        // Check if the clicked element is an input field or button
        if (event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'button') {
            // If it's an input field or button, don't execute further actions
            return;
        }
        const { id } = rowData; // Extracting standard stock id
        navigate('/bushing', { state: { bushing_id: id  } }); // Passing standard_id to the next page
    }

    useEffect(() => {
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        setRows(bushings);
    }, [bushings]);

  

    useEffect(() => {
        const filteredBushings = bushings.filter(bushing => (
            bushing.bushing_size.toString().toLowerCase().includes(filter.toLowerCase()) ||
            bushing.kg.toString().toLowerCase().includes(filter.toLowerCase())
        ));
        setRows(filteredBushings);
    }, [filter, bushings]);
  
    function showBushingModal() {
        setShowModal(true);
    }
    function hideBushingModal() {
        setShowModal(false);
    }

    const cols = [
        {
            field: 'bushing_size',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily:" system-ui", fontSize:"17px" }}>Size</Typography>
            ),
            width: 250,
            renderCell: (params) =>
                editRowId === params.row.id ? (
                    <TextField
                        name="bushing_size"
                        value={editValues.bushing_size}
                        onChange={handleInputChange}
                        onKeyDown={(e) => e.stopPropagation()}  
                    />
                ) : (
                    <div>
                    {params.value} {params.value <= 1 ? 'inch' : 'inches'}
                </div>
                ),
        },
        {
            field: 'kg',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily:" system-ui", fontSize:"17px" }}>Weight</Typography>
            ),
            width: 200,
            renderCell: (params) =>
                editRowId === params.row.id ? (
                    <TextField
                        name="kg"
                        type="number"
                        inputProps={{ step: "0.01" }}
                        value={editValues.kg}
                        onChange={handleInputChange}
                        onKeyDown={(e) => e.stopPropagation()}  
                    />
                ) : (
                    <div>
                    {params.value} {params.value <= 1 ? 'kl' : 'kls'}
                </div>
                ),
        },
        {
            field: 'bushing_quantity',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Stock</Typography>
            ),
            minWidth: 170,
            renderCell: (params) => {
                const matchingRows = updatedBushingData.filter(row => row.bushing_id === params.row.id);
                const totalQuantity = matchingRows.reduce((total, row) => total + row.quantity, 0);
                return (
                    <div>
                        {totalQuantity}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily:" system-ui", fontSize:"17px" }}>Actions</Typography>
            ),
            width: 200,
            renderCell: (params) =>
                editRowId === params.row.id ? (
                    <div>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                            style={{ marginRight: 8 }}
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'darkgray', '&:hover': { backgroundColor: 'gray' } }}
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'primary', '&:hover': { backgroundColor: 'blue' } }}
                            style={{ marginRight: 8 }}
                            onClick={() => handleEditClick(params.row)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'darkred', '&:hover': { backgroundColor: 'red' } }}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </Button>
                    </div>
                ),
        },
    ];
    
    return (
        <div className="page">
            {showModal && <AddBushingModal hideBushingModal={hideBushingModal} actionType={ADD_BUSHING}/>}
            
            <div className="stock-list">
                {successMessage && (
                   <SuccessModal open={successModalOpen} handleClose={() => setSuccessModalOpen(false)} message={successMessage} duration={1000} />
                )}
                    <div className="header-container">
                        <p className="header-title">TIGER BR. BUSHING</p>
                    </div>
                <div className="stock-list__toolbar">
                    <div className="stock-list__toolbar__search">
                        <SearchIcon className="stock-list__toolbar__search__icon"/>
                        <input
                            type="text"
                            placeholder="Search here"
                            value={filter}
                            onChange={(e) => handleOnFilterChange(e)}
                        />
                    </div>
                    <div>
                        <button className="btn" onClick={showBushingModal}>Add Bushing</button>   
                    </div>
                </div>
                <div className="employee-list__table">
                    <DataGrid
                        rows={rows}
                        columns={cols}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick
                        autoHeight
                        onRowClick={(params, event) => { // Pass event object as the second argument
                            handleOnRowClick(event, params.row); // Pass event as the first argument
                        }}
                        style={{ backgroundColor: 'white' }}
                    />    
                </div>
            </div>
            <ConfirmationModal
                open={confirmModalOpen}
                handleClose={() => setConfirmModalOpen(false)}
                handleConfirm={confirmDelete}
            />
        </div>
    );
}

export default BushingStockList;
