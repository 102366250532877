import axios from "axios";

export const confirmDelete = async (deleteRowId, asCastData, fetchData, setAscast, setDeleteRowId, setConfirmModalOpen) => {
    try {
        const response = await axios.put(`http://178.128.110.90/api/as_cast/delete/${deleteRowId}`);
        if (response.data.includes('successfully')) {
            const updatedAsCastData = asCastData.filter((data) => data.id !== deleteRowId);
            await fetchData();
            if (typeof setAscast === 'function') {
                setAscast(updatedAsCastData);
            } else {
                console.error("setAscast is not a function:", setAscast);
            }
            setDeleteRowId(null);
            if (typeof setConfirmModalOpen === 'function') {
                setConfirmModalOpen(false);
            } else {
                console.error("setConfirmModalOpen is not a function:", setConfirmModalOpen);
            }
        } else {
            console.error("Failed to delete the row:", response.data);
        }
    } catch (error) {
        console.error("Failed to delete the row:", error);
    }
};

export const confirmDeleteFinish = async (deleteFinishRowId, finishData, fetchData, setFinish, setDeleteRowId, setConfirmFinishModalOpen) => {
    try {
        const response = await axios.put(`http://178.128.110.90/api/finish/delete/${deleteFinishRowId}`);

        if (response.data.includes('successfully')) {
            const updateFinishData = finishData.filter((data) => data.id !== deleteFinishRowId);
            await fetchData(); 
            if (typeof setFinish === 'function') {
                setFinish(updateFinishData); 
            } else {
                console.error("setFinish is not a function:", setFinish);
            }
            setDeleteRowId(null);
            if (typeof setConfirmFinishModalOpen === 'function') {
                setConfirmFinishModalOpen(false); // Check if setConfirmFinishModalOpen is a function before calling
            } else {
                console.error("setConfirmFinishModalOpen is not a function:", setConfirmFinishModalOpen);
            }
        } else {
            console.error("Failed to delete the row:", response.data);
        }
    } catch (error) {
        console.error("Failed to delete the row:", error);
    }
};


export const confirmDeleteAscastOut = async (deleteAsCastRowId, asCastOutData, fetchData, setAscastout, setDeleteRowId, setConfirmAsCastOutOpen) => {
    try {
        const response = await axios.put(`http://178.128.110.90/api/as_cast_out/delete/${deleteAsCastRowId}`);
        if (response.data.includes('successfully')) {
            const updateAscastOutData = asCastOutData.filter((data) => data.id !== deleteAsCastRowId);
            await fetchData();
            if (typeof setAscastout === 'function') {
                setAscastout(updateAscastOutData);
            } else {
                console.error("setAscastout is not a function:", setAscastout);
            }
            setDeleteRowId(null);
            if (typeof setConfirmAsCastOutOpen === 'function') {
                setConfirmAsCastOutOpen(false); // Check if setConfirmFinishModalOpen is a function before calling
            } else {
                console.error("setConfirmAsCastOutOpen is not a function:", setConfirmAsCastOutOpen);
            }
        } else {
            console.error("Failed to delete the row:", response.data);
        }
    } catch (error) {
        console.error("Failed to delete the row:", error);
    }
};
export const confirmDeleteFinishOut = async (deleteFinishOutRowId, finishOutData, fetchData, setFinishout, setDeleteRowId, setConfirmFinishOutOpen) => {
    try {
        const response = await axios.put(`http://178.128.110.90/api/finish_out/delete/${deleteFinishOutRowId}`);
        if (response.data.includes('successfully')) {
            const updateFinishOutData = finishOutData.filter((data) => data.id !== deleteFinishOutRowId);
            await fetchData();
            if (typeof setFinishout === 'function') {
                setFinishout(updateFinishOutData);
            } else {
                console.error("setAscastout is not a function:", setFinishout);
            }
            setDeleteRowId(null);
            if (typeof setConfirmFinishOutOpen === 'function') {
                setConfirmFinishOutOpen(false); // Check if setConfirmFinishModalOpen is a function before calling
            } else {
                console.error("setConfirmAsCastOutOpen is not a function:", setConfirmFinishOutOpen);
            }
        } else {
            console.error("Failed to delete the row:", response.data);
        }
    } catch (error) {
        console.error("Failed to delete the row:", error);
    }
};

export const confirmDeleteBushings = async (deleteRowId, bushingsData, fetchData, setBushings, setDeleteRowId, setConfirmBushingsModalOpen) => {
    try {
        const response = await axios.put(`http://178.128.110.90/api/bushings/delete/${deleteRowId}`);
        if (response.data.includes('successfully')) {
            const updatedBushingsData = bushingsData.filter((data) => data.id !== deleteRowId);
            await fetchData();
            if (typeof setBushings === 'function') {
                setBushings(updatedBushingsData);
            } else {
                console.error("setBushings is not a function:", setBushings);
            }
            setDeleteRowId(null);
            if (typeof setConfirmBushingsModalOpen === 'function') {
                setConfirmBushingsModalOpen(false);
            } else {
                console.error("setConfirmBushingsModalOpen is not a function:", setConfirmBushingsModalOpen);
            }
        } else {
            console.error("Failed to delete the row:", response.data);
        }
    } catch (error) {
        console.error("Failed to delete the row:", error);
    }
};
export const confirmDeleteBushingsOut = async (deleteBushingsRowId, bushingsOutData, fetchData, setBushingsout, setDeleteRowId, setConfirmBushingsOutOpen) => {
    try {
        const response = await axios.put(`http://178.128.110.90/api/bushings_out/delete/${deleteBushingsRowId}`);
        if (response.data.includes('successfully')) {
            const updateBushingsOutData = bushingsOutData.filter((data) => data.id !== deleteBushingsRowId);
            await fetchData();
            if (typeof setBushingsout === 'function') {
                setBushingsout(updateBushingsOutData);
            } else {
                console.error("setBushingsout is not a function:", setBushingsout);
            }
            setDeleteRowId(null);
            if (typeof setConfirmBushingsOutOpen === 'function') {
                setConfirmBushingsOutOpen(false); // Check if setConfirmFinishModalOpen is a function before calling
            } else {
                console.error("setConfirmBushingsOutOpen is not a function:", setConfirmBushingsOutOpen);
            }
        } else {
            console.error("Failed to delete the row:", response.data);
        }
    } catch (error) {
        console.error("Failed to delete the row:", error);
    }
};