import {ADD_FINISHOUT,  GET_FINISHOUT } from "./constants";

const outFinishReducer = (state = [], action) => {
    switch(action.type) {
        case GET_FINISHOUT:
                return [...action.payload];
        case ADD_FINISHOUT:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default outFinishReducer;