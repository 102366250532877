export const LOGIN_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USER = 'GET_USER';

export const GET_STOCKS = 'GET_STOCKS';
export const ADD_STOCKS = 'ADD_STOCKS';
export const EDIT_STOCKS = 'EDIT_STOCKS';

export const GET_ASCAST = 'GET_ASCAST';
export const ADD_ASCAST = 'ADD_ASCAST';
export const EDIT_ASCAST= 'EDIT_ASCAST';

export const GET_FINISH = 'GET_FINISH';
export const ADD_FINISH = 'ADD_FINISH';
export const EDIT_FINISH= 'EDIT_FINISH';

export const GET_BUSHING = 'GET_BUSHING';
export const ADD_BUSHING = 'ADD_BUSHING';
export const EDIT_BUSHING = 'EDIT_BUSHING';


export const GET_BUSHINGS = 'GET_BUSHINGS';
export const ADD_BUSHINGS = 'ADD_BUSHINGS';
export const EDIT_BUSHINGS = 'EDIT_BUSHINGS';

export const GET_BUSHINGSOUT = 'GET_BUSHINGSOUT';
export const ADD_BUSHINGSOUT = 'ADD_BUSHINGSOUT';
export const EDIT_BUSHINGSOUT = 'EDIT_BUSHINGSOUT';

export const GET_ASCASTOUT = 'GET_ASCASTOUT';
export const ADD_ASCASTOUT = 'ADD_ASCASTOUT';
export const EDIT_ASCASTOUT = 'EDIT_ASCASTOUT';

export const GET_FINISHOUT = 'GET_FINISHOUT';
export const ADD_FINISHOUT = 'ADD_FINISHOUT';
export const EDIT_FINISHOUT = 'EDIT_FINISHOUT'