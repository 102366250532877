import { Typography, TextField, Button, IconButton } from '@mui/material';
import { formatDateToMonthDayYear, parseDateFromMonthDayYear } from '../DateFormatter';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import React from 'react';

const renderEditableCell = (params, editRowId, editValues, handleInputChange, handleDateChange) => {
    const { id, field, value } = params;
    if (editRowId === id) {
        if (field === 'finish_out_date') {
            return (
                <TextField
                    name={field}
                    type="date"
                    value={editValues[field] ? parseDateFromMonthDayYear(editValues[field]) : ''}
                    onChange={handleDateChange}
                />
            );
        }
        return (
            <TextField
                name={field}
                value={editValues[field]}
                onChange={(e) => handleInputChange(e, id)}
                onKeyDown={(e) => e.stopPropagation()}
            />
        );
    } else {
        if (field === 'finish_out_date') {
            return formatDateToMonthDayYear(value);
        }
        return value;
    }
};

const outFinishColumns = (editRowId, editValues, handleInputChange, handleDateChange, handleAsCastOutSave, handleCancelClick, handleEditClick, handleDelete) => [
    {
        field: 'finish_out_date',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Date</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editRowId, editValues, handleInputChange, handleDateChange),
    },
       {
            field: 'customer_out',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Customer</Typography>
            ),
            minWidth: 200,
            renderCell: (params) => renderEditableCell(params, editRowId, editValues, handleInputChange, handleDateChange),
        },
   
    {
        field: 'quantity_out',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Quantity</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params),
    },
    {
        field: 'shaft_out',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Shaft Size</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params),
    },
   
    {
        field: 'actions',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Actions</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => editRowId === params.row.id ? (
            <div>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                    style={{ marginRight: 8 }}
                    onClick={handleAsCastOutSave}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: 'darkgray', '&:hover': { backgroundColor: 'gray' } }}
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
            </div>
        ) : (
            <div>
                <IconButton
                    size="small"
                    sx={{ color: 'primary.main', marginRight: 1 }}
                    onClick={() => handleEditClick(params.row)}
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    size="small"
                    sx={{ color: 'darkred' }}   
                    onClick={() => handleDelete(params.row.id)}
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        ),
    },
];

export default outFinishColumns;
