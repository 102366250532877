import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
// import { addAscast } from "../redux/ascastAction";
import { useLocation } from "react-router-dom";
import { addFinish } from "../../redux/finishAction";

const FinishInfo = (props) => {
    const dispatch = useDispatch();
    const today = new Date().toISOString().split('T')[0];

    const location = useLocation();
    const { standard_id } = location.state || {};
    console.log("check", standard_id);

    const [isOpen, setIsOpen] = useState(false);
    const [finishInfo, setFinishInfo] = useState({
        shaft: '',
        quantity: '',
        delete_flg: '0',
        finish_date: today
    });

    useEffect(() => {
        if (props.finishData) {
            setFinishInfo({
                ...props.finishData,
                delete_flg: '0'
            });
        }
    }, [props.finishData]);

    function handleChange(e) {
        const { name, value } = e.target;
        setFinishInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));
    }
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const requestData = {
                ...finishInfo,
                standard_id: standard_id // Corrected
            };
            console.log("Request data: ", requestData); // Log request data
    
            const response = await axios.post('http://178.128.110.90/api/finish', requestData);
            dispatch(addFinish(response.data.finish));
            props.handleCancel(); // Optionally, you can call a function to handle post-submission actions
        } catch (err) {
            console.error("Error submitting form: ", err);
        }
    }
    // Check if all required fields except `standard_id` have truthy values
    const isFormValid = () => {
        const { shaft, quantity, finish_date } = finishInfo;
        return shaft && quantity && finish_date;
    };

    return (
        <div className="stock-info">
            <h3 className="stock-info__title">FINISH IN INFO</h3>
            <hr className="stock-info__hr"/>
            <form className="stock-info__form" onSubmit={handleSubmit}>
                <div className="input-field">
                    <label htmlFor="finish_date">Date:</label>
                    <input 
                        type="date" 
                        id="finish_date" 
                        name="finish_date" 
                        value={finishInfo.finish_date} 
                        min={today} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                {/* <div className="input-field">
                    <label htmlFor="customer">Customer:</label>
                    <div className="input-container">
                        <input 
                            type="text" 
                            id="customer" 
                            name="customer" 
                            value={finishInfo.customer} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                </div> */}
                <div className="input-field">
                    <label htmlFor="shaft">Shaft:</label>
                    <div className="input-container">
                        <input 
                            type="text" 
                            id="shaft" 
                            name="shaft" 
                            value={finishInfo.shaft} 
                            onChange={handleChange} 
                            required 
                        />
                        <span className="inches-label">"</span>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor="quantity">Quantity:</label>
                    <div className="input-container">
                        <div
                            className="dropdown"
                            onClick={toggleDropdown}
                            tabIndex="0" // Makes it focusable
                        >
                            <input
                                type="text"
                                value={finishInfo.quantity || "Select quantity"}
                                readOnly
                                className="dropdown-input"
                            />
                            <span className="custom-arrow"></span>
                        </div>

                        {isOpen && (
                            <ul className="dropdown-menu">
                                <li 
                                    onClick={(e) => {
                                        setFinishInfo({
                                            ...finishInfo,
                                            quantity: "" // Reset quantity on 'Select quantity' click
                                        });
                                        setIsOpen(false);
                                    }} 
                                    className="option"
                                >
                                    Select quantity
                                </li>
                                {[...Array(10).keys()].map(i => (
                                    <li
                                        key={i + 1}
                                        onClick={(e) => {
                                            setFinishInfo({
                                                ...finishInfo,
                                                quantity: i + 1
                                            });
                                            setIsOpen(false); // Close dropdown after selection
                                        }}
                                        className="option"
                                    >
                                        {i + 1}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div className="stock-info__form__btns">
                    <button type="button" onClick={props.handleCancel} className="btn btn--text">Cancel</button>
                    <button type="submit" className="btn" disabled={!isFormValid()}>Save</button>
                </div>
            </form>
        </div>
    );
}

export default FinishInfo;
