import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userReducer';
import stockReducer from './stockReducer';
import bushingReducer from './bushingReducer';
import ascastReducer from './ascastReducer';
import finishReducer from './finishReducer';
import ascastOutReducer from './ascastOutReducer';
import outFinishReducer from './outfinishReducer';
import bushingsReducer from './bushingsReducer';
import bushingsOutReducer from './bushingsOutReducer';

export const store = configureStore({
    reducer: {
        user: userReducer,
        stocks: stockReducer,
        bushing: bushingReducer,
        ascast: ascastReducer,
        finish: finishReducer,
        ascastout: ascastOutReducer,
        finishout: outFinishReducer,
        bushings: bushingsReducer,
        bushingsout: bushingsOutReducer
    },
});