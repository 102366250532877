import { useEffect, useState } from "react";
import { ADD_BUSHING } from "../redux/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setBushing } from "../redux/bushingActions";

const BushingInfo = (props) => {
    const dispatch = useDispatch();

    const today = new Date().toISOString().split('T')[0];

    const [bushingInfo, setBushingInfo] = useState(props.bushingData ? {
        ...props.bushingData,
        delete_flg: '0'
    } : {
        bushing_size: '',
        kg: '',
        delete_flg: '0',
    });

    useEffect(() => {
    }, [bushingInfo, dispatch]);

    async function getAllBushing() {
        try {
            const res = await axios.get("http://178.128.110.90/api/bushing");
            dispatch(setBushing(res.data));
        } catch (err) {
            console.log(err);
        }
    }

    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        setBushingInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));
    }

    async function handleSubmit(e) {
        e.preventDefault();

        switch (props.actionType) {
            case ADD_BUSHING:
                try {
                    await axios.post("http://178.128.110.90/api/bushing", bushingInfo);
                    console.log("Im here", bushingInfo);
                    getAllBushing();
                    props.handleCancel();
                } catch (err) {
                    console.log(err, bushingInfo);
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="stock-info">
            <h3 className="stocks-info__title">TIGER BR. BUSHING STOCK INFO</h3>
            <label htmlFor="cast" className="centered-label">(AS CAST)</label>
            <hr className="stock-info__hr" />
            <form className="stock-info__form" onSubmit={e => handleSubmit(e)}>
                <div className="input-field">
                    <label htmlFor="size">Size:</label>
                    <div className="input-container">
                    <input 
                        type="text" 
                        id="bushing_size" 
                        name="bushing_size" 
                        value={bushingInfo.bushing_size} 
                        onChange={e => handleChange(e)} 
                        required 
                    />
                      <span className="inches-label">"</span>
                    </div>
                    
                </div>
                <div className="input-field">
                    <label htmlFor="kg">Weight per pc:</label>
                    <div className="input-container">
                    <input 
                        type="text" 
                        id="kg" 
                        name="kg" 
                        value={bushingInfo.kg} 
                        onChange={e => handleChange(e)} 
                        required 
                    />
                      <span className="inches-label">
                            {bushingInfo.kg <= 1 ? 'kl.' : 'kls.'}
                        </span>
                    </div>
                </div>
                <div className="stock-info__form__btns">
                    <button type="button" onClick={props.handleCancel} className="btn btn--text">Cancel</button>
                    <button type="submit" className="btn" disabled={!Object.values(bushingInfo).every(val => val)}>Save</button>
                </div>
            </form>
        </div>
    );
}

export default BushingInfo;
