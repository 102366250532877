import { GET_USER, LOGIN_USER, LOGOUT_USER } from './constants';

const userReducer = (state = null, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return action.payload;
        case LOGOUT_USER:
            return null;
        case GET_USER:
            return state;
        default:
            return state;
    }
}

export default userReducer;