import { ADD_BUSHING, GET_BUSHING} from "./constants";

export const setBushing = (data) => ({
    type: GET_BUSHING,
    payload: data
})

export const addBushing = (data) => ({
    type: ADD_BUSHING,
    payload: data
})