import React, { useState } from 'react';

function PropellerThickness() {
  const [diameter, setDiameter] = useState('');
  const [result, setResult] = useState('');
  const [thicknesses, setThicknesses] = useState({});

  const handleChange = (e) => {
    setDiameter(e.target.value);
  };

  const handleSubmit = (e) => {
    const convertedResult = parseFloat(diameter) * 25.4;
    setResult(convertedResult.toFixed(1)); // Rounds the result to 2 decimal places

    const newThicknesses = calculateThicknesses(convertedResult);
    setThicknesses(newThicknesses);
  };

  const calculateThicknesses = (convertedThickness) => {
    return {
      1.0: (convertedThickness * 0.003484).toFixed(1),
      0.95: (convertedThickness * 0.006148).toFixed(1),
      0.9: (convertedThickness * 0.007629).toFixed(1),
      0.8: (convertedThickness * 0.011111).toFixed(1),
      0.7: (convertedThickness * 0.014074).toFixed(1),
      0.6: (convertedThickness * 0.017851).toFixed(1),
      0.5: (convertedThickness * 0.022370).toFixed(1),
      0.4: (convertedThickness * 0.026666).toFixed(1),
      0.3: (convertedThickness * 0.031703).toFixed(1),
      0.25: (convertedThickness * 0.034074).toFixed(1),
      0.2: (convertedThickness * 0.036888).toFixed(1),
    };
  };

  return (
    <div className="propeller-containers ">
      <h2 className="header">PROPELLER THICKNESS</h2>
          <input
            type="number"
            value={diameter}
            onChange={handleChange}
            step="any"
            placeholder="Enter diameter"
            className="input-field"
          />
      <button className="calculate-button"  onClick={handleSubmit}>CALCULATE</button>
      {result && (
        <div className="result-container">
          <p className="resultText">The converted value in mm: {result}mm </p>
          <h3 className="subHeader">Propeller Thickness Results:</h3>
          <ul className="list">
            {Object.entries(thicknesses).map(([key, value]) => (
              <li key={key} className="listItem">
                <span className="key">{key}:</span> <span className="value">{value}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PropellerThickness;
