import { Outlet } from "react-router-dom";
import DrawerOption from "./DrawerOption"; // Import the DrawerOption component
import { Box } from '@mui/system';
import { useMediaQuery, useTheme, SwipeableDrawer, Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

const Layout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect screen size
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Header */}
      <Box sx={{ bgcolor: '#10439F', color: 'white', p: 2, position: 'fixed', width: '100%', height: 64, zIndex: 11000 }}>
        {/* Add your header content here */}
        {isMobile && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={toggleDrawer}
            sx={{ position: 'absolute', left: 16, top: 12 }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexGrow: 1, pt: '64px' }}>
        {/* Drawer for mobile */}
        {isMobile ? (
          <SwipeableDrawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
          >
            <DrawerOption />
          </SwipeableDrawer>
        ) : (
          /* Permanent drawer for larger screens */
          <Drawer
            variant="permanent"
            sx={{ width: 240, flexShrink: 0 }}
            PaperProps={{ style: { position: 'relative' } }}
          >
            <DrawerOption />
          </Drawer>
        )}

        {/* Main content area */}
        <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
          {/* Render the child routes */}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
   

export default Layout;
