import { Typography, TextField, Button, IconButton } from '@mui/material';
import { formatDateToMonthDayYear, parseDateFromMonthDayYear } from '../DateFormatter';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import React from 'react';

const renderEditableCell = (params, editAscastRowId, editAscastValues, handleInputChange, handleDateChange) => {
    const { id, field, value } = params;
    if (editAscastRowId === id) {
        console.log("id", id);
        if (field === 'ascast_date') {
            return (
                <TextField
                    name={field}
                    type="date"
                    value={editAscastValues[field] ? parseDateFromMonthDayYear(editAscastValues[field]) : ''}
                    onChange={handleDateChange}
                />
            );
        }
        return (
            <TextField
                name={field}
                value={editAscastValues[field]}
                onChange={(e) => handleInputChange(e, id)}
                onKeyDown={(e) => e.stopPropagation()}
            />
        );
    } else {
        if (field === 'ascast_date') {
            return formatDateToMonthDayYear(value);
        }
        return value;
    }
};

const getAsCastColumns = (editAscastRowId, editAscastValues, handleInputChange, handleDateChange, handleSaveClick, handleCancelClick, handleEditClick, handleDelete) => [
    {
        field: 'quantity',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Quantity</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editAscastRowId, editAscastValues, handleInputChange, handleDateChange),
    },
    {
        field: 'shaft',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Shaft Size</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editAscastRowId, editAscastValues, handleInputChange, handleDateChange),
    },
    {
        field: 'ascast_date',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Date</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editAscastRowId, editAscastValues, handleInputChange, handleDateChange),
    },
    // {
    //     field: 'remaining_quantity',
    //     headerName: (
    //         <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Remaining Quantity</Typography>
    //     ),
    //     minWidth: 200,
    //     renderCell: (params) => renderEditableCell(params, editRowId, editValues, handleInputChange, handleDateChange),
    // },
    {
        field: 'actions',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Actions</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => editAscastRowId === params.row.id ? (
            <div>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                    style={{ marginRight: 8 }}
                    onClick={() => handleSaveClick(params.row.id)}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: 'darkgray', '&:hover': { backgroundColor: 'gray' } }}
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
            </div>
        ) : (
            <div>
                <IconButton
                    size="small"
                    sx={{ color: 'primary.main', marginRight: 1 }}
                    onClick={() => handleEditClick(params.row)}
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    size="small"
                    sx={{ color: 'darkred' }}
                    onClick={() => handleDelete(params.row.id)}
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        ),
    },
];

export default getAsCastColumns;
