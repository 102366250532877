import React from 'react';
import PropellerThickness from './PropellerThickness';
import PitchDegrees from './PitchDegrees';

const PropellerMeasurement = () => {
    return (
        <div className="wrapper">
          <div className="propeller-container">
            <PropellerThickness />
          </div>
          <div className="pitch-container">
            <PitchDegrees />
          </div>
        </div>
      );
    };    

export default PropellerMeasurement;