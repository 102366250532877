import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const ConfirmationModal = ({ open, handleClose, handleConfirm }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                {/* <Typography id="modal-modal-title" variant="h6" component="h2" sx={{color: 'red'}}>
                    Confirm Delete
                </Typography> */}
                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <WarningAmberIcon color="warning" sx={{ fontSize: 80 }} />
                </Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to delete this?
                </Typography>
                <Box mt={3} display="flex" justifyContent="space-between">
                    <Button variant="contained" sx={{ bgcolor: 'gray' , '&:hover': { backgroundColor: 'gray' } }} onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="contained" sx={{ bgcolor: 'red' , '&:hover': { backgroundColor: 'red' } }} onClick={handleConfirm}>
                        Yes
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
