// import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";

const ProtectedPage = () => {
    // const {user} = useSelector(store => store);

    const user = window.sessionStorage.user ? JSON.parse(window.sessionStorage.user): null;
    
    return (
        user ? <Outlet/> : <Navigate to='/login' />
    );
}

export default ProtectedPage;