import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  CssBaseline,
  Typography,
  IconButton,
  InputAdornment,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loginUser } from '../redux/userActions';

const defaultTheme = createTheme();

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    axios.post('http://178.128.110.90/api/auth', { ...loginData })
      .then((res) => {
        if (res.data === 'Success') {
          console.log('Logged in successfully');
          setError(false);
          dispatch(loginUser(loginData));
          window.sessionStorage.user = JSON.stringify(loginData);
          navigate('/');
        } else {
          console.log('Invalid credentials');
          setError(true);
          // Handle specific error messages for email and password
          setEmailError(res.data.emailError || '');
          setPasswordError(res.data.passwordError || '');
        }
      })
      .catch((err) => alert(err));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(../propeller.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src="/images/unicast.png" alt="unicast" className="unicast"  />
            <Typography component="h1" variant="h5" sx={{ fontSize: '3rem', fontFamily: 'Arial, sans-serif'}} >
              LOG IN 
            </Typography>
            <Box component="form" noValidate onSubmit={handleOnSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={loginData.email}
                onChange={handleChange}
                error={Boolean(emailError)}
                helperText={emailError}
              />
              {emailError && (
                <Typography color="error" variant="body2">
                  {emailError}
                </Typography>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={loginData.password}
                onChange={handleChange}
                error={Boolean(passwordError)}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {passwordError && (
                <Typography color="error" variant="body2">
                  {passwordError}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                SUBMIT
              </Button>
              {error && (
                <Typography color="error" variant="body2">
                  Invalid credentials, please try again.
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
