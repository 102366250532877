import FinishOutInfo from "../infoModals/FinishOutInfo";

const AddFinishOutModal = (props) => {
    
    return (
        <div className="modal" >
            <FinishOutInfo handleCancel={props.hideFinishOutModal}  finishOutDataQuantity={props.dataWithRemaining} actionType={props.actionType}/>
        </div>
    );
}

export default AddFinishOutModal;
