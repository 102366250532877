    import { Typography, TextField, Button, IconButton } from '@mui/material';
    import { formatDateToMonthDayYear, parseDateFromMonthDayYear } from '../DateFormatter';
    import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
    import React from 'react';

    const renderEditableCell = (params, editFinishRowId, editFinishValues, handleInputChange, handleDateChange) => {
        const { id, field, value } = params;
        if (editFinishRowId === id) {
            if (field === 'finish_date') {
                return (
                    <TextField
                        name={field}
                        type="date"
                        value={editFinishValues[field] ? parseDateFromMonthDayYear(editFinishValues[field]) : ''}
                        onChange={handleDateChange}
                    />
                );
            }
            return (
                <TextField
                    name={field}
                    value={editFinishValues[field]}
                    onChange={(e) => handleInputChange(e, id)}
                    onKeyDown={(e) => e.stopPropagation()}
                />
            );
        } else {
            if (field === 'finish_date') {
                return formatDateToMonthDayYear(value);
            }
            return value;
        }
    };

    const getFinishColumns = (editFinishRowId, editFinishValues, handleInputChange, handleDateChange, handleSaveClick, handleCancelClick, handleEditClick, handleDelete) => [
        {
            field: 'quantity',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Quantity</Typography>
            ),
            minWidth: 200,
            renderCell: (params) => {
            const { value, row } = params;
            const quantity = value;
            const remainingQuantity = row.remaining_quantity; // Assuming remaining_quantity is part of the row data
                return (
                    <Typography>
                    {/* {remainingQuantity} out of {quantity}  */}
                    {remainingQuantity} 
                    </Typography>   
                );
             },
        },
        {
            field: 'shaft',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Shaft Size</Typography>
            ),
            minWidth: 200,
            renderCell: (params) => renderEditableCell(params, editFinishRowId, editFinishValues, handleInputChange, handleDateChange),
        },
        {
            field: 'finish_date',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Date</Typography>
            ),
            minWidth: 200,
            renderCell: (params) => renderEditableCell(params, editFinishRowId, editFinishValues, handleInputChange, handleDateChange),
        },
        {
            field: 'actions',
            headerName: (
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Actions</Typography>
            ),
            minWidth: 200,
            renderCell: (params) =>
                editFinishRowId === params.row.id ? (
                    <div>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                            style={{ marginRight: 8 }}
                            onClick={() => handleSaveClick(params.row.id)}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'darkgray', '&:hover': { backgroundColor: 'gray' } }}
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div>
                      <IconButton   
                            size="small"
                            sx={{ color: 'primary.main', marginRight: 1 }}
                            onClick={() => handleEditClick(params.row)}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                        size="small"
                        sx={{ color: 'darkred' }}
                        onClick={() => handleDelete(params.row.id)}
                         >
                        <DeleteIcon />
                        </IconButton>
                    </div>
                ),
        },
    ];

    export default getFinishColumns;
