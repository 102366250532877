import { useEffect, useState } from "react";
import { ADD_STOCKS, EDIT_STOCKS } from "../redux/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setStocks } from "../redux/stockActions";

const StockInfo = (props) => {
    const dispatch = useDispatch();
    const today = new Date().toISOString().split('T')[0];

    const [stockInfo, setStockInfo] = useState(props.stockData ? {
        ...props.stockData,
        delete_flg: '0'
    } : {
        size: '',
        hl: '',
        shaft_size: '',
        delete_flg: '0'
    });

    useEffect(() => {}, [stockInfo, dispatch]);

    async function getAllStocks() {
        try {
            const res = await axios.get("http://178.128.110.90/api/standard_stock");
            dispatch(setStocks(res.data));
        } catch (err) {
            console.log(err);
        }
    }

    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        setStockInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));
    }

    // function calculateTotalStock() {
    //     const asCastValue = parseFloat(stockInfo.as_cast) || 0;
    //     const finishedValue = parseFloat(stockInfo.finished) || 0;
    //     return asCastValue + finishedValue;
    // }

    async function handleSubmit(e) {
        e.preventDefault();
        switch (props.actionType) {
            case ADD_STOCKS:
                try {
                    await axios.post("http://178.128.110.90/api/standard_stock", stockInfo);
                    getAllStocks();
                    props.handleCancel();
                } catch (err) {
                    console.log(err, stockInfo);
                }
                break;
            case EDIT_STOCKS:
                try {
                    await axios.put(`http://178.128.110.90/api/standard_stock/${props.stockData.id}`, stockInfo);
                    props.setStockDetail(stockInfo);
                    props.handleCancel();
                } catch (err) {
                    console.log(err);
                }
                break;
            default:
                break;
        }
    }
    const isFormValid = () => {
        const { hl, size } = stockInfo;
        return hl && size;
    };


    return (
        <div className="stock-info">
            <h3 className="stock-info__title">STD. PROPELLER STOCK INFO</h3>
            <hr className="stock-info__hr"/>
            <form className="stock-info__form" onSubmit={handleSubmit}>
                {/* <div className="input-field">
                    <label htmlFor="date">Date:</label>
                    <input 
                        type="date" 
                        id="date" 
                        name="date" 
                        value={stockInfo.date} 
                        min={today} 
                        onChange={handleChange} 
                        required 
                    />
                </div> */}
                <div className="input-field-group">
                    <div className="input-field">
                        <label htmlFor="size">Size:</label>
                        <div className="input-container">
                        <input type="text" id="size" name="size" value={stockInfo.size} onChange={handleChange} required/>
                        <span className="inches-label">in</span>
                    </div>
                    </div>
                    <div className="input-field">
                        <label htmlFor="hl">HL:</label>
                        <div className="input-container">
                        <input type="text" id="hl" name="hl" value={stockInfo.hl} onChange={handleChange} required/>
                        <span className="inches-label">"</span>
                        </div>
                      
                    </div>
                </div>
                <div className="input-field-group">
                    <div className="input-field">
                        <label htmlFor="shaft_size">Shaft Size:</label>
                        <div className="input-container">
                        <input type="text" id="shaft_size" name="shaft_size" value={stockInfo.shaft_size} onChange={handleChange}/>
                        <span className="inches-label">"</span>
                        </div>

                      
                    </div>
                    {/* <div className="input-field">
                        <label htmlFor="as_cast_wt">As Cast Wt.:</label>
                        <div className="input-container">
                        <input type="text" id="as_cast_wt" name="as_cast_wt" value={stockInfo.as_cast_wt} onChange={handleChange} required/>
                        <span className="inches-label">
                            {stockInfo.as_cast_wt <= 1 ? 'kl.' : 'kls.'}
                        </span>

                        </div>
                    </div> */}
                </div>
                <div className="stock-info__form__btns">
                    <button type="button" onClick={props.handleCancel} className="btn btn--text">Cancel</button>
                    <button type="submit" className="btn"  disabled={!isFormValid()} >Save</button>
                </div>
            </form>
        </div>
    );
}

export default StockInfo;
