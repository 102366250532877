import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Typography, Tabs, Tab } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import { parseDateFromMonthDayYear } from "../components/DateFormatter";
import ConfirmationModal from "../components/ConfirmationModal";
import SuccessModal from "../components/SuccessModal";
import AddBushingsModal from "../components/addModals/AddBushingsModal";
import AddBushingsOutModal from "../components/addModals/AddBushingsOutModal";
import { setBushings } from "../redux/bushingsAction";
import { setBushingsout } from "../redux/bushingsOutAction";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchBushingsData, fetchBushingsOutData, getAllBushing } from "../services/apiService";
import { confirmDeleteBushingsOut, confirmDeleteBushings } from "../services/confirmDeleteFunctions";
import getBushingColumns from "../components/columns/getBushingsColumns";
import outBushingColumns from "../components/columns/outBushingColumns";
import { setBushing } from "../redux/bushingActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Bushing = () => {
    const [showModal, setShowModal] = useState(false);
    const [showOutModal, setShowOutModal] = useState(false);
    const [activeTab, setActiveTab] = useState('asCast');
    const [successMessage, setSuccessMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [editRowId, setEditRowId] = useState(null);
    const [editBushingRowId, setEditBushingRowId] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [editBushingValues, setEditBushingValues] = useState({});
    const [deleteRowId, setDeleteRowId] = useState(null);
    const [deleteBushingsRowId, setDeleteBushingsRowId] = useState(null);
    const [confirmBushingsModalOpen, setConfirmBushingsModalOpen] = useState(false);
    const [confirmBushingsOutOpen, setConfirmBushingsOutOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const location = useLocation();

    const { bushing_id } = location.state || {};
    const bushingsData = useSelector(store => store.bushings);
    const bushingsOutData = useSelector(store => store.bushingsout);
    const bushingData = useSelector(store => store.bushing);
    const filteredStockData = bushingData.filter(data => data.id === bushing_id);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
    const fetchData = async () => {
        try {
            const bushings = await fetchBushingsData(bushing_id);
            dispatch(setBushings(bushings));
            const bushingsOut = await fetchBushingsOutData(bushing_id);
            dispatch(setBushingsout(bushingsOut));
            const bushing = await getAllBushing();
            dispatch(setBushing(bushing));
            console.log("Fetched bushing data:", bushing);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };
    const handleInputChange = (e, isBushingOut = false) => {
        const { name, value } = e.target;
        if (isBushingOut) {
            setEditBushingValues({ ...editBushingValues, [name]: value });
        } else {
            setEditValues({ ...editValues, [name]: value });
        }
    };
    const handleDateChange = (e, isBushingOut = false) => {
        const { name, value } = e.target;
        if (isBushingOut) {
            setEditBushingValues({ ...editBushingValues, [name]: value });
        } else {
            setEditValues({ ...editValues, [name]: value });
        }
    };
    const handleEditClick = (row, isBushingOut = false) => {
        if (isBushingOut) {
            setEditBushingRowId(row.id);
            setEditBushingValues(row);
        } else {
            setEditRowId(row.id);
            setEditValues(row);
        }
    };
    const handleSaveClick = async (isBushingOut = false) => {
        try {
           
            const rowId = isBushingOut ? editBushingValues.id : editValues.id;
            const currentBushingsDate = editValues.bushings_date;
            const cucurrentBushingsOutDate = editBushingValues.bushings_out_date;

            const formattedBushingsDate = parseDateFromMonthDayYear(currentBushingsDate);
            const formattedBushingsOutDate = parseDateFromMonthDayYear(cucurrentBushingsOutDate);

            const updatedValues = {
                ...editValues,
                bushings_date: formattedBushingsDate,
            };
            const updatedBushingsValues = {
                ...editBushingValues,
                bushings_out_date: formattedBushingsOutDate,
            };


            const endpoint = isBushingOut ? `http://178.128.110.90/api/bushings_out/${rowId}` : `http://178.128.110.90/api/bushings/${rowId}`;
            await axios.put(endpoint, isBushingOut ? updatedBushingsValues : updatedValues);

            if (isBushingOut) {
                dispatch(setBushingsout(bushingsOutData.map(row => (row.id === rowId ? updatedBushingsValues : row))));
            } else {
                dispatch(setBushings(bushingsData.map(row => (row.id === rowId ? updatedValues : row))));
            }
            fetchData();
            setEditRowId(null);
            setEditBushingRowId(null);
            setEditValues({});
            setEditBushingValues({});
            setSuccessMessage('Data successfully updated.');
            setSuccessModalOpen(true);
        } catch (error) {
            console.error("Failed to save the changes:", error);
        }
    };
    const handleCancelClick = (isBushingOut = false) => {
        if (isBushingOut) {
            setEditBushingRowId(null);
            setEditBushingValues({});
        } else {
            setEditRowId(null);
            setEditValues({});
        }
    };
    const handleDelete = (rowId, isBushingOut = false) => {
        if (isBushingOut) {
            setDeleteBushingsRowId(rowId);
            setConfirmBushingsOutOpen(true);
        } else {
            setDeleteRowId(rowId);
            setConfirmBushingsModalOpen(true);
        }
    };
    const handleConfirmDelete = () => {
        confirmDeleteBushings(deleteRowId, bushingsData, fetchData, dispatch(setBushings), setDeleteRowId, setConfirmBushingsModalOpen);
    };
    const handleConfirmDeleteBushingsOut = () => {
        confirmDeleteBushingsOut(deleteBushingsRowId, bushingsOutData, fetchData, dispatch(setBushingsout), setDeleteBushingsRowId, setConfirmBushingsOutOpen);
    };
    useEffect(() => {
        fetchData();
    }, [bushing_id]);

    const bushingsColumns = getBushingColumns(
        editRowId,
        editValues,
        (e) => handleInputChange(e, false),
        (e) => handleDateChange(e, false),
        () => handleSaveClick(false),
        () => handleCancelClick(false),
        (row) => handleEditClick(row, false),
        (rowId) => handleDelete(rowId, false)
    );

    const outbushingColumns = outBushingColumns(
        editBushingRowId,
        editBushingValues,
        (e) => handleInputChange(e, true),
        (e) => handleDateChange(e, true),
        () => handleSaveClick(true),
        () => handleCancelClick(true),
        (row) => handleEditClick(row, true),
        (rowId) => handleDelete(rowId, true)
    );

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };

    const calculateRemaining = (bushingsData, bushingsOutData) => {
        let totalRemaining = 0;

        const dataWithRemaining = bushingsData.map(data => {
            let remainingQuantity = Number(data.quantity);

            bushingsOutData.forEach(out => {
                if (Number(out.bushings_id) === Number(data.bushings_id)) {
                    remainingQuantity -= Number(out.quantity_out);
                }
            });

            totalRemaining += remainingQuantity;

            return {
                ...data,
                remaining_quantity: remainingQuantity,
            };
        });

        return {
            dataWithRemaining: dataWithRemaining,
            totalRemaining: totalRemaining,
        };
    };

    const { dataWithRemaining, totalRemaining } = calculateRemaining(bushingsData, bushingsOutData);
    const rowsBushings = dataWithRemaining.map(row => ({
        id: row.bushings_id,
        ...row
    }));

    
    console.log("bushing", bushingData);
    return (
        <div className="page">
            {showModal && <AddBushingsModal hideAddBushingsModal={() => setShowModal(false)} />}
            {showOutModal && <AddBushingsOutModal hideAddBushingsOutModal={() => setShowOutModal(false)} dataWithRemaining={dataWithRemaining} />}
            <div className="stock-list">
                {successMessage && (
                    <SuccessModal open={successModalOpen} handleClose={() => setSuccessModalOpen(false)} message={successMessage} duration={1000} />
                )}
                   <ArrowBackIcon 
                    style={{ 
                        color: hover ? 'darkblue' : 'black', 
                        fontSize: '37px' 
                    }} 
                    onClick={handleBack}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    />
                <label className="titles">TIGER BR. BUSHING  DETAILS</label>
                {filteredStockData.length > 0 && (
                    <div className="detail-container">
                        <div className="details details-dark-blue">
                            <label>Bushing Size</label>
                            <span>{filteredStockData[0].bushing_size}</span>
                        </div>
                        <div className="details details-dark-green">
                            <label>Kg</label>
                            <span>{filteredStockData[0].kg}</span>
                        </div>
                    </div>
                )}
                <div className="stock-list__toolbar">
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Bushing" value="asCast" style={{ fontSize: '20px', height: '60px', fontWeight: 'bold' }} />
                    </Tabs>
                </div>
                <Typography variant="h5" style={{ marginTop: '20px', color: '#444', fontWeight: 'bold' }}>
                    Total Stocks on Hand: {totalRemaining}
                </Typography>
                <div className="data-grid-container">
                    <div className="data-grid" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '15px', marginBottom: '20px' }}>
                        <div className="data-align" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
                                    Bushing Stock In
                                </Button>
                            </div>
                            <Typography variant="subtitle1" style={{ color: 'red', fontWeight: 'bold', fontSize: 18 }}>
                                Total: {totalRemaining}
                            </Typography>
                        </div>
                        <Typography variant="h5" gutterBottom style={{ marginTop: '10px', color: '#333', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                            Bushing In List
                        </Typography>
                        <DataGrid
                            rows={rowsBushings}
                            columns={bushingsColumns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            disableRowSelectionOnClick
                            autoHeight
                        />
                    </div>
                    <div className="data-grid" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '15px', marginBottom: '20px' }}>
                        <div className="data-align" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <Button variant="contained" color="primary" onClick={() => setShowOutModal(true)}>
                                Bushing Stock Out
                                </Button>
                            </div>
                        </div>
                        <Typography variant="h5" gutterBottom style={{ marginTop: '10px', color: '#333', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                            Bushing Out List
                        </Typography>
                        <DataGrid
                            rows={bushingsOutData}
                            columns={outbushingColumns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            disableRowSelectionOnClick
                            autoHeight
                        />
                    </div>
                </div>
            </div>
            <ConfirmationModal
                open={confirmBushingsModalOpen}
                handleClose={() => setConfirmBushingsModalOpen(false)}
                handleConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this row?"
            />
            <ConfirmationModal
                open={confirmBushingsOutOpen} 
                handleClose={() => setConfirmBushingsOutOpen(false)}
                handleConfirm={handleConfirmDeleteBushingsOut}
                message="Are you sure you want to delete this row?"
            />
        </div>
    );
}

export default Bushing;
