import { ADD_BUSHING, GET_BUSHING } from "./constants";

const bushingReducer = (state = [], action) => {
    switch(action.type) {
        case GET_BUSHING:
            return [...action.payload];
        case ADD_BUSHING:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default bushingReducer;