import FinishInfo from "../infoModals/FinishInfo";

const AddFinishModal = (props) => {
    
    return (
        <div className="modal" >
            <FinishInfo handleCancel={props.hideFinishModal} actionType={props.actionType}/>
        </div>
    );
}

export default AddFinishModal;
