import { ADD_BUSHINGS, GET_BUSHINGS } from "./constants"

export const setBushings = (data) => ({
    type: GET_BUSHINGS,
    payload: data
})
export const addBushings = (data) => ({
    type: ADD_BUSHINGS,
    payload: data
})
