import React, { useState } from 'react';

function Dashboard() {
  return (
    <div className="dashboard">
      <h1>Propeller Dashboard</h1>
      
      <div className="custom-image-card-container">
      <div className="custom-image-wrapper">
        <img src="/images/1.jpg" alt="Propeller" className="custom-propeller-image" />
      </div>
      <div className="custom-image-wrapper">
        <img src="/images/2.jpg" alt="Propeller" className="custom-aperture-image" />
      </div>
      <div className="custom-image-wrapper">
        <img src="/images/3.jpg" alt="Propeller" className="custom-aperture-image" />
      </div>
      <div className="cards">
      <div className="card-header">CONVERSIONS</div>
      <div className="card-contents">
      <div className="content-row">
      <p>Meters to feet - 3.8(x)</p>
      </div>
      <div className="content-row">
      <p>Feet to meters - 0.3048(/)</p>
      </div>
      <div className="content-row">
      <p>Inches to feet - 0.0833</p>
      </div>
      <div className="content-row">
      <p>Inches to mm - 2.54(*)</p>
      </div>
      </div>
      </div>
    </div>
      
    <div className="section">
  <h2>DAR MWB</h2>
  <div className="card-container">
    <div className="card">
      <div className="card-header">3 Blades</div>
      <div className="card-content">
        <div className="content-row">
          <p>.375:</p> <code>25-27%</code>
        </div>
        <div className="content-row">
          <p>.400:</p> <code>29-30%</code>
        </div>
        <div className="content-row">
          <p>.450:</p> <code>33-34%</code>
        </div>
        <div className="content-row">
          <p>.500:</p> <code>35%</code>
        </div>
        <div className="content-row">
          <p>.550:</p> <code>40-41%</code>
        </div>
        <div className="content-row">
          <p>.600:</p> <code>45%</code>
        </div>
        <div className="content-row">
          <p>.65:</p> <code>48-49%</code>
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header">4 Blades</div>
      <div className="card-content two-columns">
      <div className="content-row">
      <p>.475:</p> <code>27%</code>
      </div>
      <div className="content-row">
      <p>.500%:</p> <code>28-29%</code>
      </div>
      <div className="content-row">
      <p>.525: </p><code>30%</code>
      </div>
      <div className="content-row">
      <p>.550:</p> <code>31-32%</code>
      </div>
      <div className="content-row">
      <p>.600:</p> <code>35%</code>
      </div>
      <div className="content-row">
      <p>.650: </p><code>38-39%</code>
      </div>
      <div className="content-row">
      <p>.625: </p><code>36%</code>
      </div>
      <div className="content-row">
      <p>.700: </p><code>40-41%</code>
      </div>
      <div className="content-row">
      <p>.750:</p> <code>43-44%</code>
      </div>
      <div className="content-row">
      <p>.810: </p><code>49%</code>
      </div>
      <div className="content-row">
      <p>.850:</p> <code>53%</code>
      </div>
      <div className="content-row">
      <p>.90: </p><code>54%</code>
      </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header">5 Blades</div>
      <div className="card-content">
      <div className="content-row">
      <p>.600%:</p> <code>28%</code>
      </div>
      <div className="content-row">
      <p>.700:</p> <code>33.5%</code>
      </div>
      <div className="content-row">
      <p>.900:</p> <code>42%</code>
      </div>
      <div className="content-row">
      <p>.950:</p> <code>43%</code>
      </div>
      <div className="content-row">
      <p>.1:</p> <code>44%</code>
      </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header">RT/KT Blades</div>
      <div className="card-content">
      <div className="content-row">
      <p>RT 3B:</p> <code>.375</code>
      </div>
      <div className="content-row">
      <p>KT 3B:</p> <code>.550</code>
      </div>
      <div className="content-row">
      <p>RT 4B:</p> <code>.450</code>
      </div>
      <div className="content-row">
      <p>KT 4B:</p> <code>.625</code>
      </div>
      </div>
    </div>
  </div>
  <div className="horizontal-card-container">
      <div className="card-header">FORMULAS</div>
      <ul className="five-columns">
          <li>Hub Major = Major Bore x 1.75 or 2</li>
          <li>Blade Height = Diameter - Hub Major/2</li>
          <li>Developed Area = D2π /4</li>
          <li>Z = Overall Blade Area x BH/10 x No. of Blades</li>
          <li>DAR = Z/Developed Area</li>
        </ul>
  </div>
</div>
</div>
  );
}

export default Dashboard;
