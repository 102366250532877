// import AsCastInfo from "../infoModals/AsCastInfo";
import BushingsInfo from "../infoModals/BushingsInfo";

const AddBushingsModal = (props) => {
    
    return (
        <div className="modal" >
            <BushingsInfo handleCancel={props.hideAddBushingsModal} actionType={props.actionType}/>
        </div>
    );
}

export default AddBushingsModal;
