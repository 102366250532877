import { GET_USER, LOGIN_USER, LOGOUT_USER } from './constants';

export const loginUser = (userCredentials) => ({
    type: LOGIN_USER,
    payload: userCredentials
})

export const logoutUser = () => ({
    type: LOGOUT_USER
})

export const getUser = () => ({
    type: GET_USER
})