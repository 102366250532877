// utils/dateFormatter.js
export const formatDateToMonthDayYear = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
};

export const parseDateFromMonthDayYear = (formattedDate) => {
    const dateObj = new Date(formattedDate);
    if (isNaN(dateObj.getTime())) {
        return null; // Invalid date, return null
    }

    const year = dateObj.getFullYear();
    let month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    let day = dateObj.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
};
