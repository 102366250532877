import { ADD_ASCAST, GET_ASCAST } from "./constants"

export const setAscast = (data) => ({
    type: GET_ASCAST,
    payload: data
})
export const addAscast = (data) => ({
    type: ADD_ASCAST,
    payload: data
})
