
import StockInfo from "./StockInfo";

const AddStockModal = (props) => {
    
    return (
        <div className="modal" >
            <StockInfo handleCancel={props.hideAddStockModal} actionType={props.actionType}/>
        </div>
    );
}

export default AddStockModal;
