import {ADD_BUSHINGSOUT,  GET_BUSHINGSOUT } from "./constants";

const bushingsOutReducer = (state = [], action) => {
    switch(action.type) {
        case GET_BUSHINGSOUT:
                return [...action.payload];
        case ADD_BUSHINGSOUT:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default bushingsOutReducer;