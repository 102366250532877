import AsCastInfo from "../infoModals/AsCastInfo";

const AddAsCastModal = (props) => {
    
    return (
        <div className="modal" >
            <AsCastInfo handleCancel={props.hideAddAsCastModal} actionType={props.actionType}/>
        </div>
    );
}

export default AddAsCastModal;
