import { ADD_ASCAST, GET_ASCAST} from "./constants";

const ascastReducer = (state = [], action) => {
    switch(action.type) {
        case GET_ASCAST:
            return [...action.payload];
        case ADD_ASCAST:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default ascastReducer;