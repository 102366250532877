import { Typography, TextField, Button, IconButton } from '@mui/material';
import { formatDateToMonthDayYear, parseDateFromMonthDayYear } from '../DateFormatter';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import React from 'react';

const renderEditableCell = (params, editBushingRowId, editBushingValues, handleInputChange, handleDateChange) => {
    const { id, field, value } = params;
    if (editBushingRowId === id) {
        // console.log("id", editRowId);
        // console.log("ids", id);
        if (field === 'bushings_out_date') {
            return (
                <TextField
                    name={field}
                    type="date"
                    value={editBushingValues[field] ? parseDateFromMonthDayYear(editBushingValues[field]) : ''}
                    onChange={handleDateChange}
                />
            );
        }
        return (
            <TextField
                name={field}
                value={editBushingValues[field]}
                onChange={(e) => handleInputChange(e, id)}
                onKeyDown={(e) => e.stopPropagation()}
            />
        );
    } else {
        if (field === '    req.body.quantity_out,') {
            return formatDateToMonthDayYear(value);
        }
        return value;
    }
};

const outBushingColumns = (editBushingRowId, editBushingValues, handleInputChange, handleDateChange, handleSaveClick, handleCancelClick, handleEditClick, handleDelete) => [
    {
        field: 'bushings_out_date',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Date</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editBushingRowId, editBushingValues, handleInputChange, handleDateChange),
    },
    {
        field: 'customer',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Customer</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editBushingRowId, editBushingValues, handleInputChange, handleDateChange),
    },
    {
        field: 'quantity_out',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Quantity</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params),
    },
    {
        field: 'shaft_size_out',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}> Size</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params),
    },  
 
    {
        field: 'actions',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Actions</Typography>
        ),
        minWidth: 200,
        renderCell: (params) =>
            editBushingRowId === params.row.id ? (
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                        style={{ marginRight: 8 }}
                        onClick={handleSaveClick}
                    >
                        Save
                    </Button>
                    
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'darkgray', '&:hover': { backgroundColor: 'gray' } }}
                        onClick={handleCancelClick}
                    >
                        Cancel
                    </Button>
                </div>
            ) : (
                <div>
                  <IconButton
                        size="small"
                        sx={{ color: 'primary.main', marginRight: 1 }}
                        onClick={() => handleEditClick(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                    size="small"
                    sx={{ color: 'darkred' }}
                    onClick={() => handleDelete(params.row.id)}
                     >
                    <DeleteIcon />
                    </IconButton>
                </div>
            ),
    },
];

export default outBushingColumns;
