import { Box } from '@mui/system';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { styled } from '@mui/system';

const drawerWidth = 240;

const CustomListItem = ({ text, icon, hoverIcon, to, onClick, iconSize = 24 }) => {
    const [isHovered, setIsHovered] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    // Determine if this item is the current active route
    const isActive = location.pathname === to;

    const handleClick = () => {
        if (to) {
            navigate(to);
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <ListItem disablePadding>
            <CustomListItemButton
                onClick={handleClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                    backgroundColor: isActive ? '#e1f1fd' : '#ffffff', // Active background color
                    '&:hover': {
                        backgroundColor: '#e1f1fd', // Change background color on hover
                        '& .MuiSvgIcon-root': {
                            color: 'darkblue', // Change icon color on hover
                        },
                        '& .MuiListItemText-primary': {
                            color: 'darkblue', // Change title color on hover
                        },
                    },
                    '.MuiListItemText-primary': {
                        fontFamily: 'Helvetica', // Custom font family
                        fontSize: '16px',
                        color: isActive ? 'darkblue' : 'inherit', // Active text color
                    },
                }}
            >
                <ListItemIcon>
                    {typeof icon === 'string' ? (
                        <img
                            src={isHovered && hoverIcon ? hoverIcon : icon}
                            alt={text}
                            style={{
                                width: `${iconSize}px`,
                                height: `${iconSize}px`,
                            }}
                        />
                    ) : (
                        React.cloneElement(icon, {
                            sx: {
                                width: `${iconSize}px`, // Set the width of the MUI icon
                                height: `${iconSize}px`, // Set the height of the MUI icon
                                color: isActive ? 'darkblue' : 'inherit', // Active icon color
                            },
                        })
                    )}
                </ListItemIcon>
                <ListItemText primary={text} />
            </CustomListItemButton>
        </ListItem>
    );
};

// Styled component for the ListItemButton with hover effect
const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'lightblue', // Change to the desired hover background color
    },
}));

const handleLogout = () => {
    console.log('logout');
    window.sessionStorage.clear();
    window.location.reload();
};

export default function DrawerOption() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
                {/* Add your app bar content here */}
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        marginTop: '64px',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar sx={{ marginBottom: 2, marginTop: 2 }}>
                    <img src="/images/unicast.png" alt="Logo" style={{ width: 80, height: 80, marginRight: 3 }} />
                    <Typography variant="h6" noWrap sx={{ fontFamily: 'Helvetica', fontSize: '24px', fontWeight: 'bold', color: '#10439F' }}>
                        UNICAST
                    </Typography>
                </Toolbar>
                <Divider />
                <List>
                    <CustomListItem text="Dashboard" icon={<HomeIcon />} to="/dashboard" />
                    <CustomListItem text="Standard" icon="/images/propIcon.ico" hoverIcon="/images/propsb.ico" to="/" />
                    <CustomListItem text="Bushing" icon="/images/bush.ico" hoverIcon="/images/bushb.ico" to="/bushing-list" iconSize={35} />
                    <CustomListItem text="Propeller Measurement" icon="/images/measure1.ico" hoverIcon="/images/measure2.ico" to="/propeller-measurement" iconSize={28} />
                    <CustomListItem text="Logout" icon={<ExitToAppIcon />} onClick={handleLogout} />
                </List>
            </Drawer>
        </Box>
    );
}
