import { ADD_FINISH, GET_FINISH } from "./constants";

const finishReducer = (state = [], action) => {
    switch(action.type) {
        case GET_FINISH:
            return [...action.payload];
        case ADD_FINISH:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default finishReducer;