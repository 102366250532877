// src/services/apiService.js
import axios from 'axios';
import { formatDateToMonthDayYear } from "../components/DateFormatter";

const apiUrl = 'http://178.128.110.90/api';

export const fetchAsCastData = async (standard_id) => {
    try {
        const res = await axios.get(`${apiUrl}/as_cast/${standard_id}`);
        return res.data.map((row) => ({
            ...row,
            id: row.ascast_id, // Use ascast_id as id
            ascast_date: formatDateToMonthDayYear(row.ascast_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const fetchAllAsCastData = async () => {
    try {
        const res = await axios.get(`${apiUrl}/as_cast`);
        return res.data.map((row) => ({
            ...row,
            id: row.ascast_id, // Use ascast_id as id
            ascast_date: formatDateToMonthDayYear(row.ascast_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};


export const fetchAsCastOutData = async (standard_id) => {
    try {
        const res = await axios.get(`${apiUrl}/as_cast_out/${standard_id}`);
        return res.data.map((row) => ({
            ...row,
            id: row.ascast_out_id, // Use ascast_id as id
            ascast_out_date: formatDateToMonthDayYear(row.ascast_out_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const fetchFinishOutData = async (standard_id) => {
    try {
        const res = await axios.get(`${apiUrl}/finish_out/${standard_id}`);
        return res.data.map((row) => ({
            ...row,
            id: row.finish_out_id, // Use ascast_id as id
            finish_out_date: formatDateToMonthDayYear(row.finish_out_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const fetchFinishAllOutData = async () => {
    try {
        const res = await axios.get(`${apiUrl}/finish_out`);
        return res.data.map((row) => ({
            ...row,
            id: row.finish_out_id, // Use ascast_id as id
            finish_out_date: formatDateToMonthDayYear(row.finish_out_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const fetchFinishData = async (standard_id) => {
    try {
        const res = await axios.get(`${apiUrl}/finish/${standard_id}`);
        return res.data.map((row) => ({
            ...row,
            id: row.finish_id, // Use ascast_id as id
            finish_date: formatDateToMonthDayYear(row.finish_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export const fetchAllFinishData = async () => {
    try {
        const res = await axios.get(`${apiUrl}/finish`);
        return res.data.map((row) => ({
            ...row,
            id: row.finish_id, // Use ascast_id as id
            finish_date: formatDateToMonthDayYear(row.finish_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};


export const fetchBushingsData = async (bushing_id) => {
    try {
        const res = await axios.get(`${apiUrl}/bushings/${bushing_id}`);
        return res.data.map((row) => ({
            ...row,
            id: row.bushings_id, // Use ascast_id as id
            bushings_date: formatDateToMonthDayYear(row.bushings_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const fetchAllBushingsData = async () => {
    try {
        const res = await axios.get(`${apiUrl}/bushings`);
        return res.data.map((row) => ({
            ...row,
            id: row.bushings_id, // Use ascast_id as id
            bushings_date: formatDateToMonthDayYear(row.bushings_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const fetchBushingsOutData = async (bushing_id) => {
    try {
        const res = await axios.get(`${apiUrl}/bushings_out/${bushing_id}`);
        return res.data.map((row) => ({
            ...row,
            id: row.bushings_out_id, // Use ascast_id as id
            bushings_out_date: formatDateToMonthDayYear(row.bushings_out_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const fetchAllBushingsOutData = async () => {
    try {
        const res = await axios.get(`${apiUrl}/bushings_out`);
        return res.data.map((row) => ({
            ...row,
            id: row.bushings_out_id, // Use ascast_id as id
            bushings_out_date: formatDateToMonthDayYear(row.bushings_out_date) // Format date here
        }));
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const getAllBushing = async () => {
    try {
        const res = await axios.get(`${apiUrl}/bushing`);
        return res.data.map((row) => ({
            ...row,
            id: row.id, // Use bushing_id as id
            date: formatDateToMonthDayYear(row.date) // Example of formatting date
        }));
    } catch(err) {
        console.log(err);
        throw err;
    }
};
export const getAllStocks = async () => {
    try {
        const res = await axios.get(`${apiUrl}/standard_stock   `);
        return res.data.map((row) => ({
            ...row,
            id: row.id, // Use bushing_id as id
            date: formatDateToMonthDayYear(row.date) // Example of formatting date
        }));
    } catch(err) {
        console.log(err);
        throw err;
    }
};

export const getBushingsData = async (bushingIds) => {
    try {
      const data = await Promise.all(
        bushingIds.map(id => fetchBushingsData(id))
      );
      return data.flat();
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  
  
  // Fetch data for multiple bushings out
  export const getBushingsOutData = async (bushingIds) => {
    try {
      const data = await Promise.all(
        bushingIds.map(id => fetchBushingsOutData(id))
      );
      return data.flat();
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

