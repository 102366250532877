// SuccessModal.js

import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessModal = ({ open, handleClose, message, duration }) => {
  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        handleClose();
      }, duration);
    }
    return () => clearTimeout(timer);
  }, [open, handleClose, duration]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", padding: "40px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", width: "400px", textAlign: "center" }}>
        <CheckCircleIcon sx={{ fontSize: 80, color: "#4CAF50" }} />
        <IconButton style={{ position: "absolute", top: 10, right: 10 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" sx={{ marginBottom: "20px", color: "#4CAF50" }}>Update Successful!</Typography>
        <Typography variant="body1" sx={{ fontSize: "18px" }}>{message}</Typography>
      </div>
    </Modal>
  );
};

export default SuccessModal;
