import React, { useState } from 'react';
// import { FaCheckCircle } from 'react-icons/fa';

const PitchDegrees = () => {
  const [value, setValue] = useState('');
  const [result, setResult] = useState(null);
  const [percentages, setPercentages] = useState({
    '10%': null,
    '20%': null,
    '30%': null,
    '40%': null,
    '50%': null,
    '60%': null,
    '70%': null,
    '80%': null,
    '90%': null,
  });

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const calculateDegrees = () => {
    const degrees = (parseFloat(value) / 12) * 25.4;
    setResult(degrees.toFixed(2)); // Round to 2 decimal places

    const tenPercent = degrees / 3;
    const twentyPercent = tenPercent * 2;
    const thirtyPercent = degrees;
    const fortyPercent = twentyPercent / 2 * 4;
    const fiftyPercent = fortyPercent / 4 *5;
    const sixtyPercent = fiftyPercent / 5 * 6;
    const seventyPercent = sixtyPercent / 6 * 7;
    const eightyPercent = seventyPercent / 7 * 8;
    const ninetyPercent = eightyPercent / 8 * 9;
    setPercentages({
      '10°': tenPercent.toFixed(2),
      '20°': twentyPercent.toFixed(2),
      '30°': thirtyPercent.toFixed(2),
      '40°': fortyPercent.toFixed(2),
      '50°': fiftyPercent.toFixed(2),
      '60°': sixtyPercent.toFixed(2),
      '70°': seventyPercent.toFixed(2),
      '80°': eightyPercent.toFixed(2),
      '90°': ninetyPercent.toFixed(2),
    });
  };

  return (
    <div className="pitch-containers">
  <h2 className="headers">PITCH DEGREES</h2>
      <label>
        <input className="input-field" type="number"  placeholder="Enter pitch value" value={value} onChange={handleChange} />
      </label>
      <button className="calculate-button" onClick={calculateDegrees}>CALCULATE</button>
      {result !== null && (
       <div className="result-container">
       <p className="pitch-result">The converted value in mm: {result}mm </p>
       <p className="percentage-header">Pitch Degrees Results:</p>
       <ul className="percentage-list">
         {Object.entries(percentages).map(([percentage, value]) => (
           <li key={percentage} className="result-item">
             {/* <FaCheckCircle className="check-icon" /> */}
             <span className="percentage-label">{percentage}:</span>
             <span className="percentage-value">{value} </span>
           </li>
         ))}
       </ul>
     </div>
      )}
    </div>
  );
};

export default PitchDegrees;
