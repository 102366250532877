import { ADD_FINISH, GET_FINISH } from "./constants"

export const setFinish = (data) => ({
    type: GET_FINISH,
    payload: data
})

export const addFinish = (data) => ({
    type: ADD_FINISH,
    payload: data
})