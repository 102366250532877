import { Typography, TextField, Button, IconButton } from '@mui/material';
import { formatDateToMonthDayYear, parseDateFromMonthDayYear } from '../DateFormatter';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import React from 'react';

const renderEditableCell = (params, editRowId, editValues, handleInputChange, handleDateChange) => {
    const { id, field, value } = params;
    if (editRowId === id) {
        // console.log("gid", editRowId);
        // console.log("gids", id);
        if (field === 'bushings_date') {
            return (
                <TextField
                    name={field}
                    type="date"
                    value={editValues[field] ? parseDateFromMonthDayYear(editValues[field]) : ''}
                    onChange={handleDateChange}
                />
            );
        }
        return (
            <TextField
                name={field}
                value={editValues[field]}
                onChange={(e) => handleInputChange(e, id)}
                onKeyDown={(e) => e.stopPropagation()}
            />
        );
    } else {
        if (field === 'bushings_date') {
            return formatDateToMonthDayYear(value);
        }
        return value;
    }
};

const getBushingColumns = (editRowId, editValues, handleInputChange, handleDateChange, handleSaveClick, handleCancelClick, handleEditClick, handleDelete) => [
    {
        field: 'quantity',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Quantity</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => {
        const { value, row } = params;
        const quantity = value;
        const remainingQuantity = row.remaining_quantity; // Assuming remaining_quantity is part of the row data
            return (
                <Typography>
                     {remainingQuantity} 
                {/* {remainingQuantity} out of {quantity}  */}
                </Typography>   
            );
         },
    },
    {
        field: 'shaft_size',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}> Size</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editRowId, editValues, handleInputChange, handleDateChange),
    },  
    {
        field: 'bushings_date',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Date</Typography>
        ),
        minWidth: 200,
        renderCell: (params) => renderEditableCell(params, editRowId, editValues, handleInputChange, handleDateChange),
    },
    {
        field: 'actions',
        headerName: (
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Actions</Typography>
        ),
        minWidth: 200,
        renderCell: (params) =>
            editRowId === params.row.id ? (
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                        style={{ marginRight: 8 }}
                        onClick={handleSaveClick}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'darkgray', '&:hover': { backgroundColor: 'gray' } }}
                        onClick={handleCancelClick}
                    >
                        Cancel
                    </Button>
                </div>
            ) : (
                <div>
                  <IconButton
                        size="small"
                        sx={{ color: 'primary.main', marginRight: 1 }}
                        onClick={() => handleEditClick(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                    size="small"
                    sx={{ color: 'darkred' }}
                    onClick={() => handleDelete(params.row.id)}
                     >
                    <DeleteIcon />
                    </IconButton>
                </div>
            ),
    },
];

export default getBushingColumns;
