import BushingInfo from "./BushingInfo";

const AddBushingModal = (props) => {
    
    return (
        <div className="modal">
            <BushingInfo handleCancel={props.hideBushingModal} actionType={props.actionType}/>
        </div>
    );
}

export default AddBushingModal;