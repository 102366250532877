import { ADD_STOCKS, GET_STOCKS } from "./constants";

export const setStocks = (data) => ({
    type: GET_STOCKS,
    payload: data
})

export const addStocks = (data) => ({
    type: ADD_STOCKS,
    payload: data
})