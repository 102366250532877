import axios from "axios";
import { useEffect, useState } from "react";
import { Button, TextField, Typography, IconButton, Tabs, Tab, } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import { parseDateFromMonthDayYear } from "../components/DateFormatter";
import ConfirmationModal from "../components/ConfirmationModal";
import SuccessModal from "../components/SuccessModal";
import AddAsCastModal from "../components/addModals/AddAsCastModal";
import AddFinishModal from "../components/addModals/AddFinishModal";
import AddAsCastOutModal from "../components/addModals/AddAsCastOutModal";
import AddFinishOutModal from "../components/addModals/AddFinishOutModal";
import { setAscast } from "../redux/ascastAction";
import { setAscastout } from "../redux/ascastOutAction";
import { setFinish } from "../redux/finishAction";
import { setFinishout } from "../redux/outfinishAction";
import { useLocation, useNavigate  } from "react-router-dom";
import { fetchAsCastData, fetchFinishData,fetchAsCastOutData, fetchFinishOutData, getAllStocks } from "../services/apiService";
import { confirmDelete, confirmDeleteFinish, confirmDeleteAscastOut, confirmDeleteFinishOut } from "../services/confirmDeleteFunctions";
import getAsCastColumns from "../components/columns/getAsCastColumns";
// import outAsCastColumns from "../components/columns/outAsCastColumns";
import getFinishColumns from "../components/columns/getFinishColumns";
import outFinishColumns from "../components/columns/outFinishColumns";
import { setStocks } from "../redux/stockActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const AsCastFinish = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModals, setShowModals] = useState(false);
    const [showOutModal, setshowOutModal] = useState(false);
    const [showOutFinishModal, setshowOutFinishModal] = useState(false);
    const [activeTab, setActiveTab] = useState('asCast'); // State to manage active tab
    const [hover, setHover] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [editRowId, setEditRowId] = useState(null);
    const [editAscastRowId, setEditAscastRowId] = useState(null);
    const [editAscastOutRowId, setEditAscastOutRowId] = useState(null);
    const [editFinishRowId, setEditFinishRowId] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [editAscastValues, setEditAscastValues] = useState({});
    const [editAscastOutValues, setEditAscastOutValues] = useState({});
    const [editFinishValues, setEditFinishValues] = useState({})
    const [deleteRowId, setDeleteRowId] = useState(null);
    const [deleteFinishRowId, setDeleteFinishRowId] = useState(null);
    const [deleteAsCastRowId, setDeleteAscastRowId] = useState(null);
    const [deleteFinishOutRowId, setDeleteFinishOutRowId] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [confirmFinishModalOpen, setConfirmFinishModalOpen] = useState(false);
    const [confirmAscastOutOpen, setConfirmAsCastOutOpen] = useState(false);
    const [confirmFinishOutOpen, setConfirmFinishOutOpen] = useState(false);
    const location = useLocation();
  
    const { standard_id } = location.state || {};
    const asCastData = useSelector(store => store.ascast);
    const asCastOutData = useSelector(store => store.ascastout);
    const finishData = useSelector(store => store.finish);
    const stockData = useSelector (store => store.stocks);
    const finishOutData = useSelector (store => store.finishout);
    const filteredStockData = stockData.filter(data => data.id === standard_id);
    // const dateObject = filteredStockData.length > 0 ? new Date(filteredStockData[0].date) : null;
    
    // // Format the date to show only the date part (YYYY-MM-DD)
    // const formattedDate = dateObject ? dateObject.toISOString().split('T')[0] : '';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
    const fetchData = async () => {
        try {
            const asCast = await fetchAsCastData(standard_id);
            dispatch(setAscast(asCast));
            const asCastOut = await fetchAsCastOutData(standard_id);
            dispatch(setAscastout(asCastOut));
            const finish = await fetchFinishData(standard_id);
            dispatch(setFinish(finish));
            const finishOut = await fetchFinishOutData(standard_id);
            dispatch(setFinishout(finishOut));
            const stocks = await getAllStocks();
            dispatch(setStocks(stocks));
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };
    
    const handleInputChange = (e, type = 'default') => {
        const { name, value } = e.target;
    
        if (type === 'ascast') {
            setEditAscastValues({ ...editAscastValues, [name]: value });
        } else if (type === 'ascast_out') {
            setEditAscastOutValues({ ...editAscastOutValues, [name]: value });
        } else if (type === 'finish') {
            setEditFinishValues({ ...editFinishValues, [name]: value }); // Assuming setEditThirdValues is a state setter for the third type
        } else {
            setEditValues({ ...editValues, [name]: value });
        }
    };

    const handleDateChange = (e, type = 'default') => {
        const { name, value } = e.target;
    
        if (type === 'ascast') {
            setEditAscastValues({ ...editAscastValues, [name]: value });
        } else if (type === 'ascast_out') {
            setEditAscastOutValues({ ...editAscastOutValues, [name]: value });
        } else if (type === 'finish') {
            setEditFinishValues({ ...editFinishValues, [name]: value });
        } else {
            setEditValues({ ...editValues, [name]: value });
        }
    };

    const handleEditClick = (row, type = 'default') => {
        if (type === 'ascast') {
            setEditAscastRowId(row.id);
            setEditAscastValues(row);
        } else if (type === 'ascast_out') {
            setEditAscastOutRowId(row.id);
            setEditAscastOutValues(row);
        } else if (type === 'finish') {
            setEditFinishRowId(row.id);
            setEditFinishValues(row);
        } else {
            setEditRowId(row.id);
            setEditValues(row);
        }
    };

    const handleCancelClick = (type = 'default') => {
        if (type === 'ascast') {
            setEditAscastRowId(null);
            setEditAscastValues({});
        } else if (type === 'ascast_out') {
            setEditAscastOutRowId(null);
            setEditAscastOutValues({});
        } else if (type === 'finish') {
            setEditFinishRowId(null);
            setEditFinishValues({});
        } else {
            setEditRowId(null);
            setEditValues({});
        }
    };

    const handleSaveClick = async (type = 'default') => {
        try {
            let rowId, endpoint, values, setFunction, data;

            const currentAscastDate = editAscastValues.ascast_date;
            const currentAscastOutDate = editAscastOutValues.ascast_out_date;
            const currentFinishDate = editFinishValues.finish_date;
            const currentFinishOutDate = editValues.finish_out_date;

            const formattedAsCastDate = parseDateFromMonthDayYear(currentAscastDate);
            const formattedAsCastOutDate = parseDateFromMonthDayYear(currentAscastOutDate);
            const formattedFinishDate = parseDateFromMonthDayYear(currentFinishDate);
            const formattedFinishOutDate =  parseDateFromMonthDayYear(currentFinishOutDate);

            const updatedAscastValues = {
                ...editAscastValues,
                ascast_date: formattedAsCastDate,
            };
            const updatedAscastOutValues = {
                ...editAscastOutValues,
                ascast_out_date: formattedAsCastOutDate,
            };
            const updatedFinishValues = {
                ...editFinishValues,
                finish_date: formattedFinishDate,
            };
            const updatedFinishOutValues = {
                ...editValues,
                finish_out_date: formattedFinishOutDate,
            };




            if (type === 'ascast') {
                rowId = updatedAscastValues.id;
                endpoint = `http://178.128.110.90/api/as_cast/${rowId}`;
                values = updatedAscastValues;
                setFunction = setAscast;
                data = asCastData;
            } else if (type === 'ascast_out') {
                rowId = updatedAscastOutValues.id;
                endpoint = `http://178.128.110.90/api/as_cast_out/${rowId}`;
                values = updatedAscastOutValues;
                setFunction = setAscastout;
                data = asCastOutData;
            } else if (type === 'finish') {
                rowId = updatedFinishValues.id;
                endpoint = `http://178.128.110.90/api/finish/${rowId}`;
                values = updatedFinishValues;
                setFunction = setFinish;
                data = finishData;
            } else {
                rowId = updatedFinishOutValues.id;
                endpoint = `http://178.128.110.90/api/finish_out/${rowId}`;
                values = updatedFinishOutValues;
                setFunction = setFinishout;
                data = finishOutData;
            }
    
            await axios.put(endpoint, values);
            dispatch(setFunction(data.map(row => (row.id === rowId ? values : row))));
            fetchData();
    
            if (type === 'ascast') {
                setEditAscastRowId(null);
                setEditAscastValues({});
            } else if (type === 'ascast_out') {
                setEditAscastOutRowId(null);
                setEditAscastOutValues({});
            } else if (type === 'finish') {
                setEditFinishRowId(null);
                setEditFinishValues({});
            } else {
                setEditRowId(null);
                setEditValues({});
            }
    
            setSuccessMessage('Data successfully updated.');
            setSuccessModalOpen(true);
        } catch (error) {
            console.error("Failed to save the changes:", error);
        }
    };
    

    const handleDelete = async (rowId, type = 'default') => {
        if (type === 'ascast') {
            setDeleteRowId(rowId);
            setConfirmModalOpen(true);
        } else if (type === 'ascast_out') {
            setDeleteAscastRowId(rowId);
            setConfirmAsCastOutOpen(true);
        } else if (type === 'finish') {
            setDeleteFinishRowId(rowId);
            setConfirmFinishModalOpen(true);
        } else {
            setDeleteFinishOutRowId(rowId);
            setConfirmFinishOutOpen(true)
        }
    };

    const handleConfirmDelete = () => {
        if (deleteRowId) {
            confirmDelete(deleteRowId, asCastData, fetchData, dispatch(setAscast), setDeleteRowId, setConfirmModalOpen);
            setDeleteRowId(null); // Reset the state variable
        } else if (deleteFinishRowId) {
            confirmDeleteFinish(deleteFinishRowId, finishData, fetchData, dispatch(setFinish), setDeleteFinishRowId, setConfirmFinishModalOpen);
            setDeleteFinishRowId(null); // Reset the state variable
        } else if (deleteAsCastRowId) {
            confirmDeleteAscastOut(deleteAsCastRowId, asCastOutData, fetchData, dispatch(setAscastout), setDeleteAscastRowId, setConfirmAsCastOutOpen);
            setDeleteAscastRowId(null); // Reset the state variable
        } else if (deleteFinishOutRowId) {
            confirmDeleteFinishOut(deleteFinishOutRowId, finishOutData, fetchData, dispatch(setFinishout), setDeleteFinishOutRowId, setConfirmFinishOutOpen);
            setDeleteFinishOutRowId(null); // Reset the state variable
        }
    };
    
    useEffect(() => {
        fetchData();
    }, [standard_id]);
    
    const asCastColumns = getAsCastColumns(
        editAscastRowId,
        editAscastValues,
        (e) => handleInputChange(e, 'ascast'),
        (e) => handleDateChange(e, 'ascast'),
        () => handleSaveClick('ascast'),
        () => handleCancelClick('ascast'),
        (row) => handleEditClick(row, 'ascast'),
        (rowId) => handleDelete(rowId, 'ascast')
    );

    // const outCastColumns = outAsCastColumns(
    //     editAscastOutRowId,
    //     editAscastOutValues,
    //     (e) => handleInputChange(e, 'ascast_out'),
    //     (e) => handleDateChange(e, 'ascast_out'),
    //     () => handleSaveClick('ascast_out'),
    //     () => handleCancelClick('ascast_out'),
    //     (row) => handleEditClick(row, 'ascast_out'),
    //     (rowId) => handleDelete(rowId, 'ascast_out')
    // );
    
    const finishColumns = getFinishColumns(
        editFinishRowId,
        editFinishValues,
        (e) => handleInputChange(e, 'finish'),
        (e) => handleDateChange(e, 'finish'),
        () => handleSaveClick('finish'),
        () => handleCancelClick('finish'),
        (row) => handleEditClick(row, 'finish'),
        (rowId) => handleDelete(rowId, 'finish')
    );
    const outfinishColumns = outFinishColumns(
        editRowId,
        editValues,
        (e) => handleInputChange(e, 'finish_out'),
        (e) => handleDateChange(e, 'finish_out'),
        () => handleSaveClick('finish_out'),
        () => handleCancelClick('finish_out'),
        (row) => handleEditClick(row, 'finish_out'),
        (rowId) => handleDelete(rowId, 'finish_out')
    );
    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };
    const calculateRemainingQuantity = (asCastData, asCastOutData) => {
        let totalRemainingQuantity = 0;
    
        const dataWithRemainingQuantity = asCastData.map(data => {
            let remainingQuantity = Number(data.quantity);
    
            asCastOutData.forEach(out => {
                if (Number(out.ascast_id) === Number(data.ascast_id)) {
                    remainingQuantity -= Number(out.quantity_out);
                }
            });
    
            totalRemainingQuantity += remainingQuantity;
    
            return {
                ...data,
                remaining_quantity: remainingQuantity,
            };
        });
        return {
            dataWithRemainingQuantity: dataWithRemainingQuantity,
            totalRemainingQuantity: totalRemainingQuantity,
        };
    };
    const calculateRemaining = (finishData, finishOutData) => {
        let totalRemaining = 0;

        const dataWithRemaining= finishData.map(data => {
            let remainingQuantity = Number(data.quantity);
    
            finishOutData.forEach(out => {
                if (Number(out.finish_id) === Number(data.finish_id)) {
                    remainingQuantity -= Number(out.quantity_out);
                }
            });
    
            totalRemaining += remainingQuantity;
    
            return {
                ...data,
                remaining_quantity: remainingQuantity,
            };
        });
        return {
            dataWithRemaining: dataWithRemaining,
            totalRemaining: totalRemaining,
        };
    };
    const { dataWithRemainingQuantity, totalRemainingQuantity } = calculateRemainingQuantity(asCastData, asCastOutData);
    // console.log("Total Remaining Quantity:", dataWithRemainingQuantity);
    const { dataWithRemaining, totalRemaining } = calculateRemaining(finishData, finishOutData);
    // console.log("Total Remaining:", dataWithRemaining);
    const rows = dataWithRemainingQuantity.map(row => ({
        id: row.ascast_id, // Use ascast_id as the unique id
        ...row // Spread the rest of the row data
    }));
    const rowsfinish = dataWithRemaining.map(row => ({
        id: row.finish_id, // Use ascast_id as the unique id
        ...row // Spread the rest of the row data
    }));


    return (
        <div className="page">
            {showModal && <AddAsCastModal hideAddAsCastModal={() => setShowModal(false)} />}
            {showModals && <AddFinishModal hideFinishModal={() => setShowModals(false)} />}
            {/* {showOutModal && <AddAsCastOutModal hideAsCastOutModal={() => setshowOutModal(false)}  dataWithRemainingQuantity={dataWithRemainingQuantity} />} */}
            {showOutFinishModal && <AddFinishOutModal hideFinishOutModal={() => setshowOutFinishModal(false)} dataWithRemaining={dataWithRemaining} />}
    
    
            <div className="stock-list">
                {successMessage && (
                    <SuccessModal open={successModalOpen} handleClose={() => setSuccessModalOpen(false)} message={successMessage} duration={1000} />
                )}
                     <ArrowBackIcon 
                    style={{ 
                        color: hover ? 'darkblue' : 'black', 
                        fontSize: '37px' 
                    }} 
                    onClick={handleBack}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    />
                <label className="title">STD. PROPELLER DETAILS</label>
                {filteredStockData.length > 0 && (
                    <div className="details-container">
                        <div className="detail detail-dark-green">
                            <label>Size</label>
                            <span>{filteredStockData[0].size}</span>
                        </div>
                        <div className="detail detail-dark-blue">
                            <label>HL</label>
                            <span>{filteredStockData[0].hl}"</span>
                        </div>
                        <div className="detail detail-dark-purple">
                            <label>Shaft Size</label>
                            <span>{filteredStockData[0].shaft_size}"</span>
                        </div>
                        {/* <div className="detail detail-dark-orange">
                            <label>As Cast Wt.</label>
                            <span>{filteredStockData[0].as_cast_wt}</span>
                        </div>
                        <div className="detail detail-dark-red">
                            <label>Date</label>
                            <span>{formattedDate}</span>
                        </div> */}
                    </div>
                )}
                    <div className="stock-list__toolbar">
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                            style={{ height: '60px' }} // Adjust height for the Tabs component
                        >
                            <Tab label="As Cast" value="asCast" style={{ fontSize: '20px', height: '60px', fontWeight: 'bold' }} /> {/* Adjust font size and height for Tab */}
                            <Tab label="Finish" value="finish" style={{ fontSize: '20px', height: '60px', fontWeight: 'bold' }} /> {/* Adjust font size and height for Tab */}
                        </Tabs>
                    </div>
                  <Typography variant="h5" style={{ marginTop: '20px', color: '#444', fontWeight: 'bold' }}>
                    Total Stocks on Hand: {totalRemainingQuantity + totalRemaining}   
                 </Typography>
                 {activeTab === 'asCast' && (
                 <div className="data-grid-container" >
                <div className="data-grid" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '15px', marginBottom: '20px' }}>
                     <div className="data-align" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
                                As Cast In
                            </Button>
                        </div>
                            <Typography variant="subtitle1" style={{ color: 'red', fontWeight: 'bold',  fontSize: 18 }}>
                                Total: {totalRemainingQuantity}
                            </Typography>
                    </div>
                    <Typography variant="h5" gutterBottom style={{ marginTop: '10px', color: '#333', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                        As Cast In List 
                    </Typography>
                    <DataGrid
                        rows={rows}
                        columns={asCastColumns}
                        initialState={{
                            pagination: {   
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick
                        autoHeight
                    />
                </div>
                {/* <div className="data-grid" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '15px', marginBottom: '20px' }}>
                     <div className="data-align" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <Button variant="contained" color="primary" onClick={() => setshowOutModal(true)}>
                             As Cast Out
                            </Button>
                        </div>
                    </div>
                    <Typography variant="h5" gutterBottom style={{ marginTop: '10px', color: '#333', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                        As Cast Out List
                    </Typography>
                    <DataGrid
                        rows={asCastOutData}
                        columns={outCastColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick
                        autoHeight
                    />
                </div> */}
                </div>
                  )}
                   {activeTab === 'finish' && (
                <div className="data-grid-container">
                <div className="data-grid" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '15px', marginBottom: '20px' }}>
                <div className="data-align" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <Button variant="contained" color="primary" onClick={() => setShowModals(true)}>
                            Finish In
                        </Button>
                        </div>
                        <Typography variant="subtitle1" style={{ color: 'red', fontWeight: 'bold', fontSize: 18  }}>
                            Total: {totalRemaining}
                        </Typography>
                    </div>
                    <Typography variant="h5" gutterBottom style={{ marginTop: '10px', color: '#333', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                        Finish In List
                    </Typography>
                    <DataGrid
                        rows={rowsfinish}
                        columns={finishColumns} // Adjust columns for Finish data
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick
                        autoHeight
                    />
                </div>
              <div className="data-grid" style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '15px', marginBottom: '20px' }}>
              <div className="data-align" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div>
                          <Button variant="contained" color="primary" onClick={() => setshowOutFinishModal(true)}>
                          Finish Out
                      </Button>
                      </div>
                  </div>
                  <Typography variant="h5" gutterBottom style={{ marginTop: '10px', color: '#333', fontWeight: 'bold', textAlign: 'center',color: 'red' }}>
                      Finish Out List
                  </Typography>
                  <DataGrid
                      rows={finishOutData}
                      columns={outfinishColumns} 
                      initialState={{
                          pagination: {
                              paginationModel: {
                                  pageSize: 10,
                              },
                          },
                      }}
                      pageSizeOptions={[5, 10]}
                      disableRowSelectionOnClick
                      autoHeight
                  />
              </div>
          </div>
                  )}
            </div>
          
            <ConfirmationModal
                open={confirmModalOpen}
                handleClose={() => setConfirmModalOpen(false)}
                handleConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this row?"
            />
                 <ConfirmationModal
                open={confirmFinishModalOpen}
                handleClose={() => setConfirmFinishModalOpen(false)}
                handleConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this row?"
            />
                   <ConfirmationModal
                open={confirmAscastOutOpen}
                handleClose={() => setConfirmAsCastOutOpen(false)}
                handleConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this row?"
            />
                    <ConfirmationModal
                open={confirmFinishOutOpen}
                handleClose={() => setConfirmFinishOutOpen(false)}
                handleConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this row?"
            />
        </div>
    );
}

export default AsCastFinish;
