import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './pages/Layout';
import Login from './pages/Login';
import StandardStockList from './pages/StandardStockList';
import BushingStockList from './pages/BushingStockList';
import ProtectedPage from './pages/ProtectedPage';
import PageNotFound from './pages/PageNotFound';
import Dashboard from './pages/Dashboard';
import PropellerMeasurement from './pages/PropellerMeasurement';
import AsCastFinish from './pages/AsCastFinish';
import Bushing from './pages/Bushing';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path='login' element={<Login/>}/>
      <Route path='*' element={<PageNotFound/>}/>
        <Route path='/' element={<Layout/>}>
          <Route element={<ProtectedPage/>}>
            <Route index element={<StandardStockList />}/>
            <Route path='bushing-list' element={<BushingStockList />}/>
            <Route path='propeller-measurement' element={<PropellerMeasurement />}/>
            <Route path='dashboard' element={<Dashboard />}/>
            <Route path='as-cast' element={<AsCastFinish />}/>
            <Route path='bushing' element={<Bushing/>}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
