// import AsCastInfoOut from "../infoModals/AsCastInfoOut";
import BushingsInfoOut from "../infoModals/BushingsInfoOut";

const AddBushingsOutModal = (props) => {
    return (
        <div className="modal">
            <BushingsInfoOut handleCancel={props.hideAddBushingsOutModal}  bushingsOutDataQuantity={props.dataWithRemaining} actionType={props.actionType}/>
        </div>
    );
}

export default AddBushingsOutModal;
